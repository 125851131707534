import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BasketService } from 'src/app/services/basket.service';
import { Coupon } from 'src/app/interfaces/coupon';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { BasketAction } from 'src/app/interfaces/basket-action';
import { AlertService } from 'src/app/services/alert.service';
import { HelperService } from 'src/app/services/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from 'src/app/services/application.service';
import moment from 'moment';

@Component({
  selector: 'app-select-coupon-modal',
  templateUrl: './select-coupon-modal.component.html',
  styleUrls: ['./select-coupon-modal.component.scss'],
})
export class SelectCouponModalComponent implements OnInit {
  totalCouponValueTooHigh: Boolean;
  basketId = 0;
  coupon_quantity_selected = '';
  sum: '';
  total_coupon_amount = 0;
  coupon: Coupon;
  coupons: Coupon[];
  currency: string;
  quantity: number;
  showSaveButton: boolean;

  constructor(
    public modal: NgbActiveModal,
    private basketService: BasketService,
    private helperService: HelperService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private applicationService: ApplicationService
  ) {}

  ngOnInit() {
    this.totalCouponValueTooHigh = false;
    this.setCoupons();
    // setting default currency to currency of first coupon
    this.currency = this.coupons[0].value.currency;
    this.calcTotalCouponValue();
    this.showSaveButton = true;
  }

  setCoupons() {
    this.coupons.forEach((coupon: Coupon) => {
      if (typeof coupon.coupon_quantity_selected === 'undefined') {
        coupon.coupon_quantity_selected = 0;
      }
    });
  }

  saveCoupons() {
    this.basketService
      .saveCoupons(this.basketId, this.coupons)
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          const basketAction = new BasketAction();
          basketAction.type = 'updateCoupon';
          basketAction.value = this.total_coupon_amount;
          this.basketService.emitChange(basketAction);
        } else {
          this.alertService.showPopup(
            apiResponse.result
              ? apiResponse.result
              : this.translateService.instant('ERROR'),
            'error',
            10000
          );
        }
        this.modal.dismiss();
      });
  }

  calcTotalCouponValue() {
    let count = 0;
    for (let coupon of this.coupons) {
      count += (coupon.coupon_quantity_selected || 0) * coupon.value.value;
    }
    this.total_coupon_amount = count;
  }

  checkIfTotalCouponValueTooHigh(coupon: any) {
    // if total coupon amount > subtotal, coupon amount will be too high
    this.totalCouponValueTooHigh =
      parseFloat(String(this.total_coupon_amount)) > parseFloat(this.sum);
  }
}
