import { Component, Input, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { ApiResponse } from "src/app/interfaces/api-response";
import { Article } from "src/app/interfaces/article";
import { StockNotification } from "src/app/interfaces/stockNotifications/stock-notification";
import { ArticleService } from "src/app/services/article.service";
import { FavoritesService } from "src/app/services/favorites.service";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-add-to-stock-notification-button",
  templateUrl: "./add-to-stock-notification-button.component.html",
  styleUrls: ["./add-to-stock-notification-button.component.scss"],
})
export class AddToStockNotificationButtonComponent implements OnInit {
  @ViewChild(NgbTooltip) stockTooltip: NgbTooltip;
  @Input() article: Article;
  @Input() articleCode: string;
  statusLoaded: boolean;
  messageId: string;
  cachedResults: StockNotification[] = [];
  constructor(
    private articleService: ArticleService,
    private helperService: HelperService
  ) {}

  getShowButton() {
    return typeof this.article.has_stock_notification !== "undefined";
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes["articleCode"] &&
      this.articleCode &&
      (typeof changes["articleCode"]["previousValue"] !== "undefined" ||
        typeof this.article.has_stock_notification === "undefined")
    ) {
      this.getNotificationStatus().then(() => {
        this.stockTooltip.open();
      })
    }
  }

  // Check if already on watch list
  getNotificationStatus() {
    return new Promise((resolve, reject) => {
      if (this.cachedResults && this.cachedResults.length) {
        this._checkHasStockNotification(this.cachedResults);
        resolve(true);
      } else {
        this.articleService
          .getArticleStockNotifications()
          .subscribe((apiResponse: ApiResponse) => {
            if (this.helperService.checkResponse(apiResponse)) {
              const result = apiResponse.result as StockNotification[];
              this._checkHasStockNotification(result);
              this.cachedResults = result;
              resolve(true);
            } else {
              reject();
            }
          });
      }
    });
  }

  _checkHasStockNotification(stockNotifications: StockNotification[]) {
    const isInList = stockNotifications.findIndex((r) => {
      if (r.article_code === this.article.single_article_code) {
        this.messageId = r.id;
        return true;
      }
    });
    if (isInList >= 0) {
      this.article.has_stock_notification = true;
    } else {
      this.article.has_stock_notification = false;
    }
  }

  // Add to list
  addToStockList() {
    this.articleService
      .addToStockNotificationList(this.article.single_article_code, this.article.description)
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          const result = apiResponse.result as StockNotification;
          this.article.has_stock_notification = true;
          this.cachedResults = [];
          this.messageId = result.id;
        }
      });
  }

  _remove() {
    this.articleService
      .removeFromStockNotificationList(this.messageId)
      .subscribe((apiResponse: ApiResponse) => {
        this.article.has_stock_notification = false;
      });
  }

  removeFromStockList() {
    if (this.messageId) {
      this._remove();
    } else {
      this.getNotificationStatus().then(() => {
        this._remove();
      });
    }
  }
}
