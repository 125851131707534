import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { empty, Subject } from "rxjs";
import { forkJoin, Observable, pipe } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { ApplicationService } from "./application.service";
import { ChatService } from "./chat.service";
import * as _ from "underscore";
import { Article } from "../interfaces/article";
import { ApiResponse } from "../interfaces/api-response";
import { NettPriceResult } from "../interfaces/nett-price-result";
import { AlertService } from "./alert.service";
import { HelperService } from "./helper.service";
import { StockweekFilter } from "../interfaces/stockweek-filter";
import * as moment from "moment";
import { CachedNettPrice } from "../interfaces/cached-nett-price";
import { Specification } from "../interfaces/specification";
import { StorageTypes } from "../interfaces/helpers";
@Injectable({
  providedIn: "root",
})
export class SearchService {
  // Observable string sources
  private emitChangeSource = new Subject<any>();
  private emitChangeFilterSource = new Subject<any>();
  private emitBreadcrumbsSource = new Subject<any>();
  private emitChangeDetails = new Subject<any>();
  private emitResetFilters = new Subject<any>();
  private emitSearchText = new Subject<any>();
  private emitShowSearch = new Subject<any>();
  private emitGroups = new Subject<any>();
  // Observable string streams
  changeEmitted$ = this.emitChangeSource.asObservable();
  emitChangeFilterSource$ = this.emitChangeFilterSource.asObservable();
  emitBreadcrumbsSource$ = this.emitBreadcrumbsSource.asObservable();
  showDetailsEmitted$ = this.emitChangeDetails.asObservable();
  resetFiltersEmitted$ = this.emitResetFilters.asObservable();
  groupsEmitted$ = this.emitGroups.asObservable();
  searchTextEmitted$ = this.emitSearchText.asObservable();
  showSearchEmitted$ = this.emitShowSearch.asObservable();
  cachedPromotions: any = {};
  cachedNettPrices: any = {};
  showAutoSuggest = true;
  cachedResults: any = {};
  brands: Array<string> = [];
  articleType: string;

  setBrandAndArticleType(brands: Array<string>, type: string) {
    this.brands = brands;
    this.articleType = type;
  }

  // Service message commands
  emitChange(change: any) {
    this.emitChangeSource.next(change);
  }

  // Filter change
  emitFilterChange(change: any) {
    this.emitChangeFilterSource.next(change);
  }

  emitBreadcrumbsChange() {
    this.emitBreadcrumbsSource.next(true);
  }

  // Service message commands
  showDetails(change: any) {
    this.emitChangeDetails.next(change);
  }

  // Service message commands
  showSearch(change: any) {
    this.emitShowSearch.next(change);
  }

  resetFilters(change: any) {
    this.emitResetFilters.next(change);
  }

  setGroups(change: any) {
    this.emitGroups.next(change);
  }

  constructor(
    private http: HttpClient,
    private applicationService: ApplicationService,
    private chatService: ChatService,
    private alertService: AlertService,
    private helperService: HelperService
  ) {}

  public search(
    searchText: string,
    page: number,
    pageSize: number,
    sorting: string,
    filters: any = null,
    groups: any = null,
    type: string = "search",
    searchModelCode?: boolean,
    preorderType: string = "Bicycles",
    stockWeek?: StockweekFilter,
    limitToBrands: Array<string> = [],
    limitToArticlekind: string= ""
  ) {
    let apiType = "search";
    switch (type) {
      case "search":
      case "preorder":
        apiType = "search";
        break;

      case "imagebank":
        apiType = "images";
        break;
    }

    const requestData: any = {
      text: !searchModelCode ? searchText : null,
      page: page,
      pagesize: pageSize,
      sortcolumn: sorting,
      bom: false,
      promotions: false,
      pre_order: type === "preorder",
      promotion_code: "",
      language_code: this.applicationService.getSelectedLanguage(),
      company_group_code: this.applicationService.getSelectCompanyGroupCode(),
      preorder_type: preorderType ? preorderType.toLocaleLowerCase() : null,
      stock_week: stockWeek,
      limit_to_brands: limitToArticlekind === "bicycles" ? limitToBrands : [],
      limit_to_article_kind: limitToArticlekind
    };

    // Search for model code (WG only)
    if (searchModelCode) {
      requestData["model_code"] = searchText;
    }

    if (searchText || searchText === "") {
      this.emitSearchText.next(searchText);
    }

    if (filters) {
      requestData["filterChange"] = true;
      requestData["filters"] = filters;

      // Homepage promotionscode
      if (
        filters["HOMEPROMO"] &&
        filters["HOMEPROMO"]["options"] &&
        filters["HOMEPROMO"]["options"].length
      ) {
        requestData.promotion_code = filters["HOMEPROMO"]["options"][0]["id"];
        delete filters["HOMEPROMO"];
      }

      if (filters["COMMON"] && filters["COMMON"]["options"]) {
        for (let i = 0; i < filters["COMMON"]["options"].length; i++) {
          const option = filters["COMMON"]["options"][i];
          if (option["id"] === "CMN_PROM") {
            requestData.promotions = true;
          }

          if (option["id"] === "CMN_BOM") {
            requestData.bom = true;
          }
        }
      }

      if (
        filters["COMPANY"] &&
        filters["COMPANY"]["options"] &&
        filters["COMPANY"]["options"].length
      ) {
        requestData["company"] = filters["COMPANY"]["options"][0]["id"];
      }
    }

    if (groups) {
      requestData["groups"] = groups;
    }

    return this.http.post(environment.apiendpoint + apiType, requestData);
  }

  public searchSuggestions(searchText: string) {
    if (this.showAutoSuggest) {
      return this.http
        .get(
          `${environment.apiendpoint}search/suggest?text=${encodeURIComponent(
            searchText
          )}&v=2.0"`
        )
        .pipe(
          map((response) =>
            response["result"]["suggested_results"].concat(
              response["result"]["suggested_groups"]
            )
          )
        );
    } else {
      return empty();
    }
  }

  public searchArticleSuggestions(searchText: string) {
    return this.http
      .get(
        environment.apiendpoint +
          "search?text=" +
          encodeURIComponent(searchText)
      )
      .pipe(map((response) => response["result"]));
  }

  public searchInstant(searchText: string) {
    const formattedText = encodeURIComponent(searchText);
    return this.http.get(
      `${environment.apiendpoint}search/instant?text=${formattedText}`
    );
  }

  public getTerms(searchText: string) {
    const formattedText = encodeURIComponent(searchText);
    return this.http.get(
      `${environment.apiendpoint}search/suggest/terms?text=${formattedText}&v=2.0`
    );
  }

  public articleDetails(
    modelId = null,
    articleCode = null,
    framenumber = null
  ): Observable<ApiResponse> {
    let apiUrl = "";

    if (modelId || framenumber) {
      apiUrl = "models/" + modelId;
    }

    // if (!articleCode && articleCode !== null) {
    //   articleCode = "0";
    // }

    if (articleCode) {
      apiUrl = `articles`;

      if (modelId && modelId !== "0") {
        apiUrl = `${apiUrl}/${modelId}`;
      }

      apiUrl = `${apiUrl}/${articleCode}`;
    }

    if (framenumber && articleCode) {
      apiUrl = `articles/${modelId}/${articleCode}/bomauthorisation`;
    }

    return this.http.get(environment.apiendpoint + apiUrl).pipe(
      map((response: ApiResponse) => {
        if (response && response.result) {
          const article: Article = response.result;
          this.chatService.setDepartment(
            article.company_id,
            article.article_type
          );
        }
        return response;
      })
    );
  }

  parseSpecs(rawSpecs, article?: Article) {
    let parsedSpecs = {};
    const specList = [];

    if (rawSpecs.length) {
      for (let i = 0; i < rawSpecs.length; i++) {
        const rawSpec = rawSpecs[i];
        const key =
          (rawSpec.group.rank > 9
            ? String(rawSpec.group.rank)
            : '0' + String(rawSpec.group.rank)) +
          '_' +
          rawSpec.group.label;

        // Check if there is store packaging specification
        if (rawSpec.code === 'UA-WIN' && typeof article !== 'undefined') {
          article.store_packaging = rawSpec;
          article.store_packaging.value = Object.values(rawSpec.values)[0][
            'value'
          ];
        }

        if (typeof rawSpec.values === "undefined" || (Object.values(rawSpec.values).length === 0)) {
          rawSpec.values = {};
          rawSpec.values[rawSpec.value] = {
            code: rawSpec.value
          }
        }

        if (rawSpec.values) {
          const valueIndex = Object.keys(rawSpec.values);
          let values = null;

          if (valueIndex.length > 1) {
            let descriptions = '';
            if (this.applicationService.getSelectCompanyGroupCode() !== 'LAP') {
              descriptions = '<ul class="list-unstyled ms-2">';
              for (let i = 0; i < valueIndex.length; i++) {
                let spec = rawSpec.values[
                  valueIndex.constructor === Array
                    ? valueIndex[i]
                    : valueIndex[Object.keys(valueIndex)[i]]
                ] as Specification;
                descriptions += `<li> &centerdot; ${
                  typeof spec.value !== 'undefined' ? spec.value : spec.code
                }</li>`;
              }

              descriptions += '</ul>';
            } else {
              const specList = [];
              for (let i = 0; i < valueIndex.length; i++) {
                let spec = rawSpec.values[
                  valueIndex.constructor === Array
                    ? valueIndex[i]
                    : valueIndex[Object.keys(valueIndex)[i]]
                ] as Specification;
                specList.push(
                  typeof spec.value !== 'undefined' ? spec.value : spec.code
                );
              }
              descriptions = specList.join(',');
            }

            values = rawSpec.values[
              valueIndex.constructor === Array
                ? valueIndex[0]
                : valueIndex[Object.keys(valueIndex)[0]]
            ] as Specification;

            values.value = descriptions;
          } else {
            values =
              rawSpec.values[
                valueIndex.constructor === Array
                  ? valueIndex[0]
                  : valueIndex[Object.keys(valueIndex)[0]]
              ];
            // Format to html
            if (values && values.code) {
              values.code = this.helperService.nl2br(values.code);
            }
          }

          if (typeof parsedSpecs[key] === 'undefined') {
            parsedSpecs[key] = [];
          }

          if (values) {
            parsedSpecs[key].push({
              description: rawSpec.description
                ? rawSpec.description
                : rawSpec.code,
              code: rawSpec.code,
              value: values.value ? values.value : values.code,
            });
          }
        }
      }

      // Sort specs by rank
      parsedSpecs = Object.keys(parsedSpecs)
        .sort()
        .reduce((r, k) => ((r[k] = parsedSpecs[k]), r), {});

      for (const key in parsedSpecs) {
        if (parsedSpecs.hasOwnProperty(key)) {
          const descriptionData = key.split("_");
          specList.push({
            description: descriptionData[1],
            items: parsedSpecs[key],
          });
        }
      }
    }

    return specList;
  }

  public parseImages(article) {
    const imageList = [];

    if (
      !article.img ||
      (article.img &&
        article.img.length &&
        article.img[0].indexOf("thumbs") >= 0)
    ) {
      if (!article.img) {
        article.img = article.thumbs ? article.thumbs : [];
      } else {
        article.img = article.z_img;
      }
    }

    if (
      article.img &&
      article.img.length &&
      article.img[0].indexOf("coming_soon.jpg") >= 0
    ) {
      article.img[0] = `./assets/images/${
        article.article_type === "F" ? "bicycle-solid" : "part"
      }.svg`;
      article.no_img = true;
    }

    if (article.img && article.img.length) {
      for (let i = 0; i < article.img.length; i++) {
        imageList.push({
          small: article.thumbs[i],
          medium: article.img[i].replace("=540", "=1024"),
          big:
            article.z_img && article.z_img.length && article.z_img[i]
              ? article.z_img[i]
              : article.img[i].replace("=540", "=1024"),
        });
      }
    }

    return imageList;
  }

  getArticlesByCodes(articles: string[]) {
    return this.http.get(
      environment.apiendpoint + "articles?c=" + articles.join("&c=")
    );
  }

  getCompareArticles() {
    return [];
  }

  getMultiArticles(articleIds) {
    const data = [];
    for (let i = 0; i < articleIds.length; i++) {
      if (articleIds[i]) {
        data.push(
          this.http.get(environment.apiendpoint + "models/" + articleIds[i])
        );
      }
    }
    return forkJoin(data);
  }

  getEcommerceArticles(articleCode, type) {
    return this.http.get(
      environment.apiendpoint + "articles/" + articleCode + "/" + type
    );
  }

  // Get the nett prices for a list of articles
  getNettPrices(articles, companyId?) {
    const promise = new Promise((resolve, reject) => {
      if (!localStorage.getItem("bearerToken")) {
        reject();
      }

      this.applicationService.emitRequestError.subscribe((response) => {
        if (response.error && response.error.result) {
          this.resetNettPrices(articles);
        }
      });

      if (articles && articles.length) {
        const input: any = [];
        articles.forEach((a: Article) => {
          if (a && a !== null) {
            let cachedPrice = this.cachedNettPrices[a.id] as CachedNettPrice;
            if (typeof cachedPrice === "undefined") {
              if (a.article_codes || a.single_article_code) {
                input.push({
                  article_codes: a.article_codes || [a.single_article_code],
                  id: a.id,
                  company_id: a.company_id || companyId,
                });
              }
            } else if (
              cachedPrice.timestamp.isAfter(moment().subtract(30, "minutes"))
            ) {
              a.net_price = cachedPrice.net_price;
              a.nett_prices = cachedPrice.nett_prices;
            } else {
              delete this.cachedNettPrices[a.id];
              if (a.article_codes || a.single_article_code) {
                input.push({
                  article_codes: a.article_codes || [a.single_article_code],
                  id: a.id,
                  company_id: a.company_id || companyId,
                });
              }
            }
          }
        });

        if (input.length) {
          this.http
            .post(environment.apiendpoint + "models/netprices", input)
            .subscribe((apiResponse: ApiResponse) => {
              if (
                this.helperService.checkResponse(apiResponse) &&
                apiResponse.result
              ) {
                const nettPrices: Array<NettPriceResult> = apiResponse.result;

                for (const nettPrice of nettPrices) {
                  const article: Article = _.findWhere(articles, {
                    id: nettPrice.id,
                  });
                  if (article) {

                    article.net_price = nettPrice.net_price;
                    if (!article.nett_prices) {
                      article.nett_prices = Array<NettPriceResult>();
                    }
                    article.nett_prices.push(nettPrice);

                    if (article.nett_prices.length > 1) {
                      // Set visible price to the lowest price (when multiple articles)
                      const lowest = _.min(article.nett_prices, function (n) {
                        if (n.net_price) {
                          return n.net_price.value;
                        }
                          return null;
                      }) as NettPriceResult;
                      const hightest = _.max(article.nett_prices, function (n) {
                        if (n.net_price) {
                          return n.net_price.value;
                        }
                        return null
                      }) as NettPriceResult;
                      article.net_price = lowest.net_price;
                      if (lowest.net_price) {
                        article.has_different_prices =
                          lowest.net_price.value !== hightest.net_price.value;
                      }
                    }
                    this.cachedNettPrices[nettPrice.id] = {
                      net_price: article.net_price,
                      nett_prices: article.nett_prices,
                      timestamp: moment(),
                    };
                  }
                }
                // Hide the nettprice spinner if no result
                this.resetNettPrices(articles);
              } else {
                if (apiResponse.result !== "No permission") {
                  this.alertService.showErrorNotification(
                    apiResponse.result ? apiResponse.result : "ERROR"
                  );
                }
              }
              resolve(true);
            });
        } else {
          resolve(true);
        }
      } else {
        resolve(true);
      }
    });
    return promise;
  }

  // Hide the nettprice spinner if no result
  resetNettPrices(articles: Article[]) {
    articles.forEach((article) => {
      if (article && !article.net_price) {
        article.hide_nett_price = true;
      }
    });
  }

  getRecentSearched() {
    const key = `${this.applicationService.getSelectCompanyGroupCode()}recent`;
    return this.helperService.parseJsonFromStorage(key, StorageTypes.localstorage)
  }

  saveToRecentSearched(keyWord) {
    const maxResults = 10;
    const key = `${this.applicationService.getSelectCompanyGroupCode()}recent`;

    if (keyWord) {
      let currentKeyWords = this.helperService.parseJsonFromStorage(key, StorageTypes.localstorage);

      // Check if the keyword is already in the list
      const keywordIndex = currentKeyWords.indexOf(keyWord);

      if (keywordIndex < 0) {
        // Keyword not found, add it to the beginning of the list
        currentKeyWords.unshift(keyWord);

        // If the list exceeds the maximum allowed length, trim it
        if (currentKeyWords.length > maxResults) {
          currentKeyWords = currentKeyWords.slice(0, maxResults);
        }

        // Save the updated list to local storage
        localStorage.setItem(key, JSON.stringify(currentKeyWords));
      } else {
        // Keyword found, move it to the beginning of the list
        currentKeyWords.splice(keywordIndex, 1); // Remove existing occurrence
        currentKeyWords.unshift(keyWord); // Add it to the beginning

        // Save the updated list to local storage
        localStorage.setItem(key, JSON.stringify(currentKeyWords));
      }
    }
  }

  removeRecentItem(keyWord: string) {
    const key = `${this.applicationService.getSelectCompanyGroupCode()}recent`;
    let currentKeyWords = localStorage.getItem(key)
      ? JSON.parse(localStorage.getItem(key))
      : [];

    currentKeyWords = _.without(currentKeyWords, keyWord);
    localStorage.setItem(key, JSON.stringify(currentKeyWords));
    return currentKeyWords;
  }

  getPromotions() {
    return new Promise((approve, reject) => {
      if (
        this.cachedPromotions[
          this.applicationService.getSelectCompanyGroupCode()
        ]
      ) {
        approve(
          this.cachedPromotions[
            this.applicationService.getSelectCompanyGroupCode()
          ]
        );
      } else {
        this.http
          .get(environment.apiendpoint + "promotions/")
          .subscribe((apiResponse: ApiResponse) => {
            if (
              this.helperService.checkResponse(apiResponse) &&
              apiResponse.result
            ) {
              this.cachedPromotions[
                this.applicationService.getSelectCompanyGroupCode()
              ] = apiResponse;
              approve(apiResponse);
            }
          });
      }
    });
  }

  clearPromotions() {
    this.cachedPromotions = {};
  }
}
