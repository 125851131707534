<label class="filterHeader" style="font-size: 110%">{{
  "REFINE_RESULTS" | translate
  }}</label>
<div class="input-group">
  <input type="search" class="form-control" [(ngModel)]="keyword" (search)="search()" (click)="focussed = true"
    (clickOutside)="focussed = false"
    [ngStyle]="{'border-top-right-radius': !focussed ? '4px' :'initial', 'border-bottom-right-radius': !focussed ? '4px' :'initial'}" />
  <button class="btn px-2 py-0" [ngClass]="focussed ? 'btn-dark' : ''" type="button" (click)="search()">
    <i class="material-icons mt-1">search</i>
  </button>

</div>
<div class="form-group">
  <input type="checkbox" class="form-check-input me-1" id="keep-filters" [(ngModel)]="keepFilters">
  <label class="form-check-label" for="keep-filters">{{"KEEP_FILTERS_REFINE"|translate}}</label>
</div>
