import { Component, OnInit, Input } from "@angular/core";
import { Article } from "src/app/interfaces/article";

@Component({
  selector: "app-has-replacement",
  templateUrl: "./has-replacement.component.html",
  styleUrls: ["./has-replacement.component.scss"]
})
export class HasReplacementComponent implements OnInit {
  @Input() article: Article;
  constructor() {}

  ngOnInit() {}
}
