import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Article } from "../interfaces/article";
import { environment } from "../../environments/environment";
import { DropshipmentConsumer } from "../interfaces/dropshipment/dropshipment-consumer";
import { DropshipmentBasket } from "../interfaces/dropshipment/dropshipment-basket";
import { Subject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiResponse } from "../interfaces/api-response";
import { DropshipmentArticle } from "../interfaces/dropshipment/dropshipment-article";
import { ApplicationService } from "./application.service";

@Injectable({
  providedIn: "root"
})
export class DropshipmentService {
  private emitChangeSource = new Subject<any>();
  changeEmitted$ = this.emitChangeSource.asObservable();
  constructor(private http: HttpClient, private applicationService: ApplicationService) { }

  getDropshipmentBasket() {
    return this.http.get(environment.apiendpoint + "dropshipment");
  }

  updateUserInformation(information: any) {
    return this.http.put(
      environment.apiendpoint + "dropshipment/address",
      information
    );
  }

  deleteBasket() {
    return this.http.delete(`${environment.apiendpoint}dropshipment/`);
  }

  deleteFromDropshipmentBasket(articleCode: string) {
    return this.http.delete(
      `${environment.apiendpoint}dropshipment/items/${articleCode}`
    );
  }

  updateArticle(article: DropshipmentArticle) {
    return this.http.put(
      `${environment.apiendpoint}dropshipment/items/`,
      article
    );
  }

  // Add to dropshipment basket
  addToDropshipmentBasket(
    article: Article,
    quantity: number
  ): Observable<ApiResponse> {
    return this.http
      .post(`${environment.apiendpoint}dropshipment/items/`, [
        {
          amount: quantity,
          article_code: article.single_article_code,
          dropshipment_id: -1,
          id: 0
        }
      ])
      .pipe(
        map((response: ApiResponse) => {
          this.emitChangeSource.next(article);
          return response;
        })
      );
  }

  sendOrder(basket: DropshipmentBasket) {

    let orderData = this.applicationService.isV2Basket() ? basket.customer : basket;

    return this.http.post(
      `${environment.apiendpoint}dropshipment/order/`,
      orderData
    );
  }
}
