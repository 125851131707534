import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from "@angular/core";
import {
  PreorderSettings,
  PreorderSettingsContainer,
} from "src/app/interfaces/settings/preorder-settings";
import { ApplicationService } from "src/app/services/application.service";
import * as moment from "moment";
import { Article } from "src/app/interfaces/article";
import { BasketService } from "src/app/services/basket.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { Basket } from "src/app/interfaces/basket";
import { PreorderScheduleItem } from "src/app/interfaces/preorder/preorder-schedule-item";
import { BasketArticle } from "src/app/interfaces/basketArticle";
import { PreorderEditItem } from "src/app/interfaces/preorder/preorder-edit-item";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { QrScannerComponent } from "../../search-components/qr-scanner/qr-scanner.component";
import { DeviceDetectorService } from "ngx-device-detector";
import { WishlistModalComponent } from "../wishlist-modal/wishlist-modal.component";
import { AccountService } from "src/app/services/account.service";
import { AccountInformation } from "src/app/interfaces/account-information";
import { SearchService } from "src/app/services/search.service";
import { FavoritesService } from "src/app/services/favorites.service";
import { Subscription, Scheduler, timer } from "rxjs";
import * as _ from "underscore";
import { ActivatedRoute, Router } from "@angular/router";
import { PreorderService } from "src/app/services/preorder.service";
import { HelperService } from "src/app/services/helper.service";
import { NdaCheckComponent } from "../nda-check/nda-check.component";
import { SignedCondition } from "src/app/interfaces/signed-condition";

@Component({
  selector: "app-preorder-container",
  templateUrl: "./preorder-container.component.html",
  styleUrls: ["./preorder-container.component.scss"],
})
export class PreorderContainerComponent implements OnInit {
  @Input() articles: Article[];
  @Input() settings: PreorderSettings;
  @Input() basketArticles: PreorderEditItem;
  @Input() selectedArticles: any = {};
  @Input() showButtons = true;
  @Input() edit: boolean;
  @Input() hideSaveButton: boolean;
  @Input() maxQuantity = 0;
  @Input() type: string;
  @Input() basketArticleId: number;
  @Output() qtyChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() preorderTypeChanged: EventEmitter<string> =
    new EventEmitter<string>();
  preorderSettings: PreorderSettings;
  preorderEnabled: boolean;
  basketId: number|string = 0;
  companyGroupCode = "";
  preorderCMSPageId = "";
  preorderCMSPageIds = {
    ANL: "",
    WG: "preorder-dealerbook",
    GHO: "preorder-dealerbook",
  };
  scanning: boolean;
  hasWebcam: any;
  accountInformation: AccountInformation;
  showAdditionalItems: boolean;
  scrollPosition: number;
  buttonsMaxWidth: number;
  bico: boolean;
  isHelpdesk: boolean;
  subscription: Subscription;
  private _subscription: Subscription;
  showArticles: boolean;
  preorderType = "Bicycles";
  preorderBicyclesEnabled: boolean;
  preorderPartsEnabled: boolean;
  basketIds = {};
  signedCondition: SignedCondition;
  constructor(
    public applicationService: ApplicationService,
    private basketService: BasketService,
    private searchService: SearchService,
    private modalService: NgbModal,
    private deviceDetector: DeviceDetectorService,
    private accountService: AccountService,
    private favoritesService: FavoritesService,
    private router: Router,
    private route: ActivatedRoute,
    public preorderService: PreorderService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    // Show NDA agree modal
    if (
      this.applicationService.getSelectCompanyGroupCode() === "GHO" ||
      this.applicationService.getSelectCompanyGroupCode() === "WG"
    ) {
      this.checkNda();
    } else {
      this.showArticles = true;
    }

    // Hide basket button in App bar
    this.toggleBasketPreviewButton();
    this.isHelpdesk = this.accountService.isHelpdesk();
    this.searchService.showAutoSuggest = false;

    if (!this.preorderService.preorderType) {
      const preorderType = window.location.href.includes("=parts")
        ? "Parts"
        : "Bicycles";
      this.preorderType = preorderType;
      this.preorderService.preorderType = preorderType;
    } else {
      this.preorderType = this.preorderService.preorderType;
    }

    // Chrome on iOS has no access to camera..
    const isChromeOnIos = navigator.userAgent.match("CriOS");
    if (isChromeOnIos) {
      this.hasWebcam = false;
    } else {
      if (this.deviceDetector.isMobile() || this.deviceDetector.isTablet()) {
        this.hasWebcam = true;
      } else {
        this.checkIfWebCam();
      }
    }

    this.subscription = this.favoritesService.changeEmitted$.subscribe(
      (data) => {
        const article = _.find(this.articles, { id: data["id"] });
        if (article) {
          article.is_favorite = false;
        }
      }
    );

    this.getPreorderSettings(this.preorderType);
    this.companyGroupCode = this.applicationService.getSelectCompanyGroupCode();
    this.preorderCMSPageId = this.preorderCMSPageIds[this.companyGroupCode]
      ? this.preorderCMSPageIds[this.companyGroupCode]
      : "";

    this._subscription = this.basketService.changeEmitted$.subscribe(
      (response) => {
        switch (response["type"]) {
          case "addArticle":
            const addedQuantity = response["quantity"];
            if (this.maxQuantity > 0) {
              this.maxQuantity -= addedQuantity;
              if (this.maxQuantity === 0) {
                this.modalService.dismissAll();
              }
            }
            break;
        }
      }
    );
  }

  ngAfterViewInit() {
    const container = document.querySelector(".viewOptions") as HTMLDivElement;
    if (container) {
      let width = container.offsetWidth;
      if (width < 400) {
        width = 400;
      }
      this.buttonsMaxWidth = width;
    }
  }

  ngOnDestroy() {
    if (this.type !== "wishlist" && !this.router.url.includes("basket/")) {
      // Show basket button in App bar when leaving
      this.toggleBasketPreviewButton(false);
      this.searchService.showAutoSuggest = true;
      this.preorderService.preorderType = this.preorderType;
    }
  }


  checkNda() {
    this.accountService.getNda("preorder", moment().year().toString(), true).then((response: SignedCondition) => {
      const hasSignedNda = response !== null;
      if (hasSignedNda) {
        this.signedCondition = response;
      }
      if (!hasSignedNda && !this.accountService.isHelpdesk()) {
        const reference = this.modalService.open(NdaCheckComponent, {
          container: "#modalContainer",
          windowClass: "fixed-modal medium",
          backdrop: "static",
          backdropClass: "blurred",
          centered: true,
        });

        reference.componentInstance.title = "PREORDER_AGREEMENT";
        reference.componentInstance.key = "preorder";
        reference.componentInstance.section = "preorder";
        reference.componentInstance.ts = moment().year().toString();

        // NDA Storybloks page Ids
        reference.componentInstance.pageIds = {
          ANL: 960422135,
          GHO: 960422146,
          WG: 960422147,
        };

        reference.componentInstance.checkedEvent.subscribe(() => {
          this.showArticles = true;
        });
      } else {
        this.showArticles = true;
      }
    });
  }

  _getSignedDate(value) {
    return this.helperService.formatDate(value);
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    const scrollPosition =
      window.scrollY ||
      window.pageYOffset ||
      document.body.scrollTop +
        ((document.documentElement && document.documentElement.scrollTop) || 0);

    this.scrollPosition = scrollPosition;
  }

  articleId(index, article) {
    return article.id;
  }

  private toggleBasketPreviewButton(hide = true) {
    // Hide basket button in App bar
    const basketPreviewButton = document.querySelector(
      "#basketPreview"
    ) as HTMLElement;

    if (basketPreviewButton) {
      basketPreviewButton.style.display = hide ? "none" : "initial";
    }
  }

  private checkExtraItems(accountInformation: AccountInformation) {
    return (
      (this.applicationService.getSelectCompanyGroupCode() === "WG" &&
        accountInformation.profile.dealer_level_winora) ||
      accountInformation.profile.dealer_level_haibike ||
      (this.applicationService.getSelectCompanyGroupCode() === "GHO" &&
        accountInformation.profile.dealer_level_ghost)
    );
  }

  // Loop through devices to check if there is a webcam (desktop only)
  checkIfWebCam() {
    const self = this;
    function detectWebcam(callback) {
      const md = navigator.mediaDevices;
      if (!md || !md.enumerateDevices) {
        return callback(false);
      }
      md.enumerateDevices().then((devices) => {
        callback(devices.some((device) => "videoinput" === device.kind));
      });
    }
    detectWebcam((hasWebcam) => {
      this.hasWebcam = hasWebcam;
    });
  }

  getPreorderSettings(preorderType) {
    //this.applicationService.clearPreorderSettings();
    this.preorderService
      .getPreorderSettingsOfType(preorderType)
      .then((response: PreorderSettings) => {
        this.preorderSettings = response;

        if (
          moment(this.preorderSettings.enabled.from).isSameOrBefore(moment()) &&
          moment(this.preorderSettings.enabled.untill).isSameOrAfter(moment())
        ) {
          this.preorderEnabled = true;
          if (this.preorderSettings.use_time_slots) {
            this.preorderService.startYear = Number(
              this.preorderSettings.last_possible_delivery_month.year
            );
            this.preorderSettings.months_schedule = this.renderTimeSlots(
              this.preorderSettings.last_possible_delivery_month.year,
              this.preorderSettings.last_possible_delivery_month.month,
              false
            );
            this.preorderSettings.months_schedule_bico = this.renderTimeSlots(
              this.preorderSettings.last_possible_delivery_month.year,
              this.preorderSettings.last_possible_delivery_month.month,
              true
            );
          } else {
            this.preorderService.startYear = Number(moment().format("YYYY"));
            this.preorderSettings.months_schedule = this.renderMonths(
              this.preorderSettings.last_possible_delivery_month.year,
              this.preorderSettings.last_possible_delivery_month.month
            );
          }

          this.preorderService
            .preorderEnabled("Bicycles")
            .then((response: boolean) => {
              this.preorderBicyclesEnabled = response;
              this.preorderService
                .preorderEnabled("Parts")
                .then((response: boolean) => {
                  this.preorderPartsEnabled = response;

                  if (
                    !this.preorderBicyclesEnabled ||
                    window.location.href.includes("parts")
                  ) {
                    this.preorderType = "Parts";
                  }
                  this.preorderTypeChanged.emit(this.preorderType);
                  this.preorderEnabled =
                    this.preorderPartsEnabled || this.preorderBicyclesEnabled;

                  this.accountService
                    .getAccountInformation()
                    .then((accountInformation: AccountInformation) => {
                      if (accountInformation) {
                        if (accountInformation.profile) {
                          this.bico =
                            accountInformation.profile.dealer_association ===
                            "BICO";
                        }
                        this.accountInformation = accountInformation;
                        if (
                          accountInformation.profile &&
                          this.checkExtraItems(accountInformation)
                        ) {
                          this.showAdditionalItems = true;
                        }

                        // If deeplink redirect to normal page
                        const articleCode = this.route.snapshot.paramMap.get('articleCode');
                        if (articleCode) {
                          if ((!this.preorderBicyclesEnabled && this.preorderType === "Bicycles")|| (!this.preorderPartsEnabled && this.preorderType === "Parts")) {
                            this.router.navigateByUrl(`/${this.applicationService.getSelectCompanyGroupCode()}/search?keyword=${articleCode}`)
                          }
                        }

                        this.getBasketId();
                      } else {
                        this.preorderEnabled = false;
                      }
                    });
                });
            });
        } else {
          if (preorderType === "Bicycles") {
            this.getPreorderSettings("Parts");
          }
          this.preorderEnabled = false;
        }

      });
  }

  renderMonths(lastOrderYear: number, lastOrderMonth: number) {
    const schedule = Array<PreorderScheduleItem>();

    if (lastOrderMonth > 12) {
      lastOrderMonth = 12;
    }

    const deadlineYear = lastOrderYear;
    const currentYear = moment().year();
    const currentMonth = moment().month() + 1;
    const startMonthSetting = this.applicationService.isDach() ? 9 : 1;// todo

    for (let year = currentYear; year <= deadlineYear; year++) {
      // This is the year of the deadline so we need to apply the last order month
      if (year === deadlineYear) {
        let startMonth = 1;
        if (year === currentYear) {
          startMonth = startMonthSetting ? startMonthSetting : currentMonth;
        }

        for (let i = startMonth; i <= lastOrderMonth; i++) {
          const month = i === 12 ? 1 : i + 1;
          const weekNo = this.applicationService.getWeekFromMonth(
            month,
            year,
            1
          ); // use the first day of next month
          const deliveryWeek = this.applicationService.getWeekFromMonth(
            i,
            year,
            5
          );
          schedule.push({
            week: weekNo,
            deliveryWeek: deliveryWeek,
            month: i,
            year: year,
            deliveryYear: i === 12 && deliveryWeek < 10 ? year + 1 : year,
            index: i,
          });
        }
      } else {
        // the deadline is futher in the future so first add the whole years
        for (let i = year === currentYear ? startMonthSetting : 1; i <= 12; i++) {
          const month = i === 12 ? 1 : i + 1;
          const weekNo = this.applicationService.getWeekFromMonth(
            month,
            year,
            1
          ); // use the first day of next month
          const deliveryWeek = this.applicationService.getWeekFromMonth(
            i,
            year,
            5
          );
          schedule.push({
            week: weekNo,
            deliveryWeek: deliveryWeek,
            month: i,
            year: year,
            deliveryYear: i === 12 && deliveryWeek < 10 ? year + 1 : year,
            index: i,
          });
        }
      }
    }
    return schedule;
  }

  renderTimeSlots(lastOrderYear: number, lastOrderMonth: number, bico = false) {
    const schedule = Array<PreorderScheduleItem>();

    if (lastOrderMonth > 12) {
      lastOrderMonth = 12;
    }

    for (let i of this.preorderService[
      !bico ? "availableTimeSlots" : "availableTimeSlotsBico"
    ]) {
      const weekNo = this.applicationService.getWeekFromMonth(
        i + 1,
        lastOrderYear,
        31
      );
      schedule.push({
        week: weekNo,
        deliveryWeek: this.applicationService.getWeekFromMonth(
          i,
          lastOrderYear,
          5
        ),
        month: i,
        year: lastOrderYear,
        deliveryYear: lastOrderYear,
        index: i,
      });
    }
    return schedule;
  }

  getBasketId() {
    if (!this.basketId || this.basketId === null) {
      // Check if id is in the cache
      if (this.basketIds[this.preorderType]) {
        this.basketId = this.basketIds[this.preorderType];
      } else {
        this.basketService
          .getPreorderBasket(this.preorderType)
          .subscribe((apiResponse: ApiResponse) => {
            if (this.helperService.checkResponse(apiResponse)) {
              const basket: Basket = apiResponse.result;
              this.basketId = basket.id;
              this.basketIds[this.preorderType] = basket.id;
            }
          });
      }
    }
  }

  showArticleDetails(data) {}

  startScanner() {
    this.scanning = true;
    let modalRef = this.modalService.open(QrScannerComponent, {
      ariaLabelledBy: "modal-basic-title",
      container: "#modalContainer",
      size: "lg",
      windowClass: "fixed-modal",
      backdrop: "static",
      centered: true,
    });
    modalRef.componentInstance.stopped.subscribe(() => {
      this.scanning = false;
      modalRef = null;
    });
  }

  showWishList() {
    const modalRef = this.modalService.open(WishlistModalComponent, {
      centered: true,
      size: "lg",
      container: "#modalContainer",
    });
    modalRef.componentInstance.preorderType = this.preorderType;
  }

  getSchedule(article: Article, schedule: any[]) {
    if (
      article.bico &&
      this.accountInformation &&
      this.accountInformation.profile &&
      this.accountInformation.profile.dealer_association === "BICO"
    ) {
      //console.log(schedule)
    }

    return schedule;
  }

  typeChange() {
    this.basketId = null;
    this.getPreorderSettings(this.preorderType);
    this.preorderTypeChanged.emit(this.preorderType);
  }
}
