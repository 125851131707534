import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { IsDealerGuard } from 'src/app/guards/is-dealer.guard';
import { SearchPageComponent } from 'src/app/pages/search-page/search-page.component';
import { SearchComponent } from 'src/app/components/search-components/search/search.component';
import { SharedModule } from '../shared/shared.module';
import { SearchTabsComponent } from 'src/app/components/search-components/search-tabs/search-tabs.component';
import { SideBarComponent } from 'src/app/components/search-components/side-bar/side-bar.component';
import { SearchFilterComponent } from 'src/app/components/search-components/side-bar/search-filter/search-filter.component';
import { GroupsTreeComponent } from 'src/app/components/search-components/groups-tree/groups-tree.component';
import { PromoWidgetComponent } from 'src/app/components/search-components/promo-widget/promo-widget.component';
import { SearchFilterListComponent } from 'src/app/components/search-components/side-bar/search-filter-list/search-filter-list.component';
import { ImageBankArticleContainerComponent } from 'src/app/components/image-bank-components/image-bank-article-container/image-bank-article-container.component';
import { ImageBankArticleComponent } from 'src/app/components/image-bank-components/image-bank-article/image-bank-article.component';
import { ImageBankAtmosphericComponent } from 'src/app/components/image-bank-components/image-bank-atmospheric/image-bank-atmospheric.component';
import { ReplenishmentInputsComponent } from 'src/app/components/article-components/xl/replenishment-inputs/replenishment-inputs.component';
import { FavoritesCmsContentComponent } from 'src/app/components/search-components/favorites-cms-content/favorites-cms-content.component';
import { RangeFilterComponent } from 'src/app/components/search-components/side-bar/range-filter/range-filter.component';
import { StockweekFilterComponent } from 'src/app/components/search-components/side-bar/stockweek-filter/stockweek-filter.component';
import { BrandPromoWidgetComponent } from 'src/app/components/search-components/brand-promo-widget/brand-promo-widget.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { register } from 'swiper/element/bundle';
import { SliderModule } from 'primeng/slider';
import { FilterDialogComponent } from 'src/app/components/search-components/filter-dialog/filter-dialog.component';

register();

const routes: Routes = [
  {
    path: "",
    component: SearchPageComponent,
    canActivate: [IsDealerGuard],
    data: {roles: ['partnerPortal', 'Dealer']}
  },
  {
    path: ":cgc/results",
    component: SearchPageComponent,
    canActivate: [IsDealerGuard],
    data: {roles: ['partnerPortal', 'Dealer']}
  },
  {
    path: ":text",
    component: SearchPageComponent,
    canActivate: [IsDealerGuard],
    data: {roles: ['partnerPortal', 'Dealer']}
  },
  {
    path: ":cgc/results/:text",
    component: SearchPageComponent,
    canActivate: [IsDealerGuard],
    data: {roles: ['partnerPortal', 'Dealer']}
  },
  {
    path: ":cgc/search/:text/:filters",
    component: SearchPageComponent,
    canActivate: [IsDealerGuard],
    data: {roles: ['partnerPortal', 'Dealer']}
  },
  {
    path: ":cgc/results/:text/:filters",
    component: SearchPageComponent,
    canActivate: [IsDealerGuard],
    data: {roles: ['partnerPortal', 'Dealer']}
  },
  {
    path: ":cgc/article/:text/:filters",
    component: SearchPageComponent,
    canActivate: [IsDealerGuard],
    data: {roles: ['partnerPortal', 'Dealer']}
  },
  {
    path: ":cgc/articleDetails/:id",
    component: SearchPageComponent,
    canActivate: [IsDealerGuard],
    data: {roles: ['partnerPortal', 'Dealer']}
  },
  {
    path: ":cgc/articleDetails/:id/:articleCode",
    component: SearchPageComponent,
    canActivate: [IsDealerGuard],
    data: {roles: ['partnerPortal', 'Dealer']}
  },
  {
    path: ":cgc/articleDetails/:id/:articleCode/:framenumber",
    component: SearchPageComponent,
    canActivate: [IsDealerGuard],
    data: {roles: ['partnerPortal', 'Dealer']}
  },

];

@NgModule({
  declarations: [
    SearchPageComponent,
    SearchComponent,
    SearchTabsComponent,
    SearchFilterComponent,
    GroupsTreeComponent,
    SideBarComponent,
    RangeFilterComponent,
    PromoWidgetComponent,
    SearchFilterListComponent,
    ImageBankArticleComponent,
    ImageBankArticleContainerComponent,
    ImageBankAtmosphericComponent,
    ReplenishmentInputsComponent,
    FavoritesCmsContentComponent,
    StockweekFilterComponent,
    BrandPromoWidgetComponent,
    FilterDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SliderModule
  ],
  exports: [SliderModule, SearchComponent],
})
export class SearchModule {}
