import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CompanyGroup } from "src/app/interfaces/company-group";
import { AccountService } from "src/app/services/account.service";
import { ApplicationService } from "src/app/services/application.service";
import { FinancialService } from "src/app/services/financial.service";
import { saveAs } from 'file-saver';
@Component({
  selector: "app-turnover-statistics-page",
  templateUrl: "./turnover-statistics-page.component.html",
  styleUrls: ["./turnover-statistics-page.component.scss"],
})
export class TurnoverStatisticsPageComponent implements OnInit {
  accountType: string;
  companyGroupCode: string;
  companySettings: CompanyGroup;
  companies: any = [];
  loaded: boolean = false;

  constructor(
    private financialService: FinancialService,
    private accountService: AccountService,
    private applicationService: ApplicationService,
    public router: Router
  ) {}

  ngOnInit() {
    this.accountType = this.accountService.getAccountType() ?? "";
    this.companyGroupCode = this.applicationService.getSelectCompanyGroupCode();
    this.companySettings = this.applicationService.getSettingsForCompanyGroup(
      this.companyGroupCode
    ) as CompanyGroup
    for (let companyID of this.companySettings.companies) {
      if (companyID === 101) {
        this.getFileUrl("WS");
      } else if (companyID === 102) {
        this.getFileUrl("WBP");
      }
    }
  }

  downloadPdf(companyID: string) {
    this.applicationService.showLoader(true);
    this.financialService
      .getTurnoverStatisticsPdfFile(companyID)
      .subscribe((blob: Blob) => {
        const file = new Blob([blob], { type: "application/pdf" });
        saveAs(file, "statistics.pdf");
        this.applicationService.hideLoader();
      });
  }

  getFileUrl(companyID: string) {
    this.applicationService.showLoader(true);
    this.financialService
      .getTurnoverStatisticsPdfFile(companyID)
      .subscribe((blob: Blob) => {
        const file = new Blob([blob], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        const company = {
          companyID: companyID,
          fileURL: fileURL,
        };
        this.companies.push(company);
        this.applicationService.hideLoader();
      });
  }
}
