export interface YearAgreementParts {
  year: number;
  id: number;
  DRG: boolean;
  commercial_agreements: CommercialAgreements;
  dealer_levels: DealerLevel[];
  exceptions: string;
  brands: Brand[];
  bonus: Bonus;
  dealer_ships: DealerShips;
  remarks: string;
  account_code: string;
  send: string;
  signed: string;
  locked: boolean;
}

export class YearAgreementParts implements YearAgreementParts {}

export interface DealerLevel {
  id: number;
  description: string;
  discount: number;
  brands: number;
  shipping_cost: string;
  dropshipment: boolean;
  shipping_schedule: string;
}
export interface Bonus {
  id: number;
  max_bonus_percentage: number;
  value: string;
  description: string;
  selected: boolean;
  planning: boolean;
  options: BonusOption[];
}

export interface BonusOption {
  id: number;
  selected: boolean;
  description: string;
  option_group: string;
  bonus_percentage: number;
  default_value: boolean;
}

export interface Brand {
  id: string;
  selected: boolean;
  description: string;
  discount: number;
  value: number;
  tier: number;
  has_target: boolean;
  has_planning: boolean;
  planning: boolean;
  parent_id: string;
  child_id: string;
  parts_only: number[];
  options: BrandDiscountOption[];
  premium: number[];
  target: number;
  tiers: Tier[];
}

export interface BrandDiscountOption {
  ace_discounts: string[];
  count_planning: boolean;
  description: string;
  discount: number;
  discount_editable: boolean;
  has_planning: boolean;
  has_target: boolean;
  id: string;
  planning: boolean;
  selected: boolean;
}

export interface CommercialAgreements {
  bike_dealer_ship: string;
  retail_formula: string;
  shipping_cost: string;
  dropshipment: boolean;
  shipping_schedule: string;
  revenue: number;
  target: number;
  dealer_level: number;
}

export interface DealerShips {
  group_1: Brand[];
  group_2: Brand[];
  group_3: Brand[];
  group_4: Brand[];
  presale: Brand[];
  exceptions: Brand[];
}

export interface Tier {
  value: number;
  target: number;
  discount: number;
  has_planning: boolean;
}
