<div class="container" [style.display]="searchVisible ? 'block' : 'none'">
  <div class="row p-xl-2">
    <div
      id="sidebar-wrapper"
      class="px-0 col-md-3 col-lg-3 col-xl-2 col-sm-4 pe-md-0 ps-md-2"
      *ngIf="
        (!imageBankTab || imageBankTab === 'articles' || selectedTab === 'search') && showSideBar
      "
      [ngClass]="selectedTab !== 'favorites' ? 'd-sm-block' : 'd-none'"
      [@flyIn]
    >
      <app-side-bar
        #sideBar
        style="width: 100%"
        [priceRangeData]="priceRangeData"
        [stockweekData]="stockweekFilter"
        [groups]="groups"
        [availableFilters]="availableFilters"
        [showStockFilter]="selectedTab !== 'preorder'"
        [sidebarTop]="sidebarTop"
        (groupsUpdated)="groupsUpdated($event)"
        (stockweeksUpdated)="stockweeksUpdated($event)"
        (filtersUpdated)="filtersUpdated($event)"
        (filterWithKeyword)="filterWithKeyword($event)"
        [numResults]="collectionSize"
      ></app-side-bar>
    </div>

    <div
      class="px-0 col-md-3 col-lg-2 col-xl-2 col-sm-4 pe-md-0 ps-md-2"
      *ngIf="selectedTab === 'favorites'"
    >
      <h1>
        <app-header-icon iconName="favorite"></app-header-icon
        >{{ "FAVORITES" | translate }}
      </h1>

      <ul class="mt-3 list-group list-group-flush">
        <li class="ps-sm-0 list-group-item">
          <a
            class="text-primary clickable"
            [ngClass]="{ 'fw-bold': favoritesType === 'main' }"
            (click)="favoritesType = 'main'; count = null; setUrl({},true); getResults(true)"
            >{{ "FAVORITES" | translate }}
            <ng-container *ngIf="count && favoritesType === 'main'"
              >({{ count }})</ng-container
            ></a
          >
        </li>
        <li class="ps-sm-0 list-group-item">
          <a
            class="text-primary clickable"
            [ngClass]="{ 'fw-bold': favoritesType === 'stocklist' }"
            (click)="
              favoritesType = 'stocklist'; count = null; setUrl({},true); getResults(true)
            "
            >{{ "SN_STOCK_LIST" | translate }}
            <ng-container *ngIf="count && favoritesType === 'stocklist'"
              >({{ collectionSize }})</ng-container
            ></a
          >
        </li>
      </ul>
    </div>

    <div
      class="p-0 m-0 col-xs-12 row search-container"
      [ngClass]="
        selectedTab === 'imagebank' && imageBankTab === 'atmospheric'
          ? 'col-md-12'
          : 'col-md-9 col-sm-8 col-lg-9 col-xl-10'
      "
    >
      <!-- Search results -->
      <div class="px-0 ms-0 col-md-12 mx-xs-0 px-md-2 ms-sm-2">
        <!-- View options -->
        <nav
          class="p-2 mb-2  me-0 mt-sm-2 row mb-md-1 viewOptions"
          [ngClass]="selectedTab === 'imagebank' && imageBankTab === 'atmospheric' ? 'col-md-10 ms-auto' : 'col-md-12 ms-0'"
          aria-label="viewOptions"
          [hidden]="!showViewOptions"
        >
          <div class="px-0 col-md-6 col-lg-7 col-12" [hidden]="!showSearchTabs">
            <app-search-tabs
              #searchTabs
              class="px-0 col-md-12"
              (tabSelected)="setTab($event)"
              [activeTab]="selectedTab"

            >
            </app-search-tabs>
            <h3 *ngIf="!showSearchTabs" class="mt-1">

            </h3>
          </div>
          <div
            class="ps-2 pe-0 mt-1 col-md-6 col-lg-5 d-flex align-items-center listOptions" [class.justify-content-md-end]="showSearchTabs"
            *ngIf="sideBar"
          >
            <button
              id="showFilters"
              class="fab btn d-block d-sm-none p-1 w-auto"
              style="max-width: 52px"
              *ngIf="
                selectedTab === 'search' ||
                selectedTab === 'preorder' ||
                selectedTab === 'replenishment'
              "
              [ngClass]="this.sideBar.sideBarActive ? 'btn-danger' : 'btn-dark'"
              (click)="
                this.sideBar.sideBarActive = !this.sideBar.sideBarActive;
                scrollToTop(0)
              "
            >
              <i class="material-icons"> filter_alt </i>
              <i
                class="material-icons position-absolute close-filters text-black"
                *ngIf="this.sideBar.sideBarActive"
              >
                cancel
              </i>
            </button>
            <button
              *ngIf="
                selectedTab !== 'preorder' &&
                selectedTab !== 'replenishment' &&
                selectedTab !== 'imagebank' &&
                selectedTab !== 'favorites' &&
                showSearchTabs
              "
              [ngClass]="
                viewType === 'treeview' ? 'bg-white selected border' : 'bg-transparent'
              "
              class="d-flex align-items-center px-2 py-1 btn btn-default view-button-left"
              (click)="setViewType('treeview')"
            >
              <i class="material-icons"> view_list </i>
            </button>
            <button
              *ngIf="
                selectedTab !== 'preorder' &&
                selectedTab !== 'replenishment' &&
                selectedTab !== 'imagebank' &&
                selectedTab !== 'favorites' &&
                showSearchTabs
              "
              [ngClass]="
                viewType === 'card' ? 'bg-white selected border' : 'bg-transparent'
              "
              class="d-flex align-items-center px-2 py-1 me-2 btn btn-default view-button-right"
              (click)="setViewType('card')"
            >
              <i class="material-icons"> grid_on </i>
            </button>
            <button
              *ngIf="
                selectedTab !== 'preorder' &&
                selectedTab !== 'replenishment' &&
                showSearchTabs
              "
              [ngClass]="
                viewType === 'swiper' ? 'bg-white selected border' : 'bg-transparent'
              "
              class="px-2 py-1 pb-0 me-2 btn btn-default view-button-right d-block d-sm-none"
              (click)="setViewType('swiper')"
            >
              <i class="material-icons"> favorite </i>
            </button>
            <button
              *ngIf="selectedTab === 'replenishment'"
              class="me-2 btn btn-dark view-button-right btn-sm"
              (click)="showReplenishmentSelection()"
            >
              {{ "REPLENISHMENT_SELECTION" | translate }}
              <i class="material-icons" style="font-size: 12px"> check </i>
            </button>
            <button
              *ngIf="selectedTab === 'replenishment'"
              class="me-2 btn btn-dark view-button-right btn-sm"
              [routerLink]="
                '/' + companyGroupCode + '/companyPage/' + replenishmentHelpId
              "
            >
              {{ "REPLENISHMENT_EXPLANATION" | translate }}
              <i class="material-icons" style="font-size: 12px"> help </i>
            </button>
            <div class="d-flex align-items-center" *ngIf="showSearchTabs || applicationService.isPartnerPortal()" [class.w-100]="applicationService.isPartnerPortal()">
              <label class="pe-2" *ngIf="applicationService.isPartnerPortal()">{{"SORTING"|translate}}</label>
              <select
                [ngModel]="sorting"
                style="max-width: 150px"
                *ngIf="selectedTab === 'search' || selectedTab === 'preorder'  || selectedTab === 'replenishment' || selectedTab === 'favorites'"
                name="sorting"
                class="me-md-1 form-control sorting"
                (change)="sortingChange($event.target.value)"
              >
                <option value="1" selected="">
                  {{ "RELEVANCE" | translate }}
                </option>
                <ng-container *ngIf="selectedTab !== 'favorites'">
                  <option value="2">{{ "NAME" | translate }} a-z</option>
                  <option value="3">{{ "NAME" | translate }} z-a</option>
                  <option value="4">{{ "PRICE" | translate }} 0-9</option>
                  <option value="5">{{ "PRICE" | translate }} 9-0</option>
                  <option value="6">
                    {{ "ARTICLE_NO" | translate }} {{ "ASCENDING" | translate }}
                  </option>
                  <option value="7">
                    {{ "ARTICLE_NO" | translate }} {{ "DESCENDING" | translate }}
                  </option>
                </ng-container>
                <ng-container *ngIf="selectedTab === 'favorites'">
                  <option value="3">{{ "PRICE" | translate }} 0-9</option>
                  <option value="4">{{ "PRICE" | translate }} 9-0</option>
                  <option value="8">
                    {{ "ADDED" | translate }} ({{ "ASCENDING" | translate }})
                  </option>
                  <option value="7">
                    {{ "ADDED" | translate }} ({{ "DESCENDING" | translate }})
                  </option>
                </ng-container>
              </select>
              <label class="px-2 d-none d-sm-block" *ngIf="applicationService.isPartnerPortal()">{{"NUM_RESULTS"|translate}}</label>
              <select
                [ngModel]="pageSize"
                style="max-width: 80px"
                name="pageSize"
                class="me-1 form-control d-none d-sm-block"
                *ngIf="selectedTab === 'search'"
                (change)="pageSizeChange($event.target.value)"
              >
                <option value="24">24</option>
                <option value="36">36</option>
                <option value="72">72</option>
              </select>
            </div>
          </div>
        </nav>
        <app-promo-widget
          class="d-none d-sm-block"
          [observer]="dataLoadedEmitted$"
          *ngIf="loaded && selectedTab !== 'favorites'"
        ></app-promo-widget>
        <app-brand-promo-widget [filters]="filters"></app-brand-promo-widget>
        <app-favorites-cms-content
          *ngIf="
            loaded &&
            selectedTab === 'favorites' &&
            favoritesType === 'main' &&
            !articles?.length
          "
        ></app-favorites-cms-content>
        <p
          class="p-3"
          *ngIf="
            loaded &&
            selectedTab === 'favorites' &&
            favoritesType === 'stocklist' &&
            !articles?.length
          "
        >
          <b> {{ "NO_RESULTS" | translate }}</b>
        </p>
        <div
          [ngClass]="[
            (articles && articles.length && loaded) ||
            (suggestTerms && suggestTerms.showTerms) ||
            selectedTab === 'preorder'
              ? 'hasArticles'
              : '',
            loaded ? 'loaded' : '',
            'view_' + viewType
          ]"
        >
          <ng-container
            *ngIf="selectedTab !== 'imagebank' && selectedTab !== 'preorder'"
          >
            <app-replenishment-availability-checker
              class="w-100"
              *ngIf="selectedTab === 'replenishment'"
              (isAvailable)="replenishmentAvailable = $event"
            ></app-replenishment-availability-checker>
            <div
              class="px-1 pt-sm-5 articleContainer row pt-lg-1 px-sm-0"
              [@pageAnimations]
              [@.disabled]="animationsDisabled"
              *ngIf="
                (articles.length &&
                  viewType === 'card' && selectedTab !== 'replenishment') ||
                (selectedTab === 'replenishment' && replenishmentAvailable) ||
                selectedTab === 'favorites'
              "
            >
              <ng-container
                *ngFor="
                  let article of articles;
                  trackBy: articleId;
                  let i = index
                "
              >
                <app-article-card
                  [article]="article"
                  class="card d-flex col-xl-4 col-xs-12 col-sm-6 col-md-6 align-items-stretch"
                  [ngClass]="
                    selectedTab === 'replenishment' ? 'col-lg-6' : 'col-lg-4'
                  "
                  [useLazyLoad]="i > 9"
                  (showArticleDetails)="showArticleDetails($event, i)"
                  (articleSelected)="addToSelection($event)"
                  [leaseRequest]="leaseRequest"
                  [autoShowDetails]="count === 1 || articles.length === 1"
                  [hideOverlay]="selectedTab === 'replenishment'"
                  [hideMatrix]="selectedTab === 'replenishment'"
                >
                  <div xl *ngIf="selectedTab === 'replenishment'">
                    <app-replenishment-inputs
                      class="mb-5 d-block"
                      [article]="article"
                      [selectionByModel]="replenishmentService.replenishmentSettings?.replenish_by_model"
                      (inputChanged)="replenishmentNotSaved = true"
                    ></app-replenishment-inputs>
                    <app-focus-model
                      class="replenishment-focus"
                      *ngIf="article.base_collection"
                      [size]="'default'"
                    ></app-focus-model>
                  </div>
                </app-article-card>
              </ng-container>
            </div>

            <!-- Treeview -->
            <app-tree-view
              #treeview
              *ngIf="
                viewType !== 'card' &&
                viewType !== 'swiper' &&
                articles.length &&
                selectedTab === 'search'
              "
              [articles]="articles"
              [leaseRequest]="leaseRequest"
              class="px-3 py-0 w-100 h-100 py-sm-3 pe-lg-4"
              [observer]="dataLoadedEmitted$"
              (showArticleDetails)="showArticleDetails($event, treeview.index)"
              (articleSelected)="addToSelection($event)"
              (articleCheckedChange)="addToSelection($event)"
            >
            </app-tree-view>
            <app-fav-swiper
              [articles]="articles"
              *ngIf="
                viewType === 'swiper' &&
                articles.length &&
                selectedTab === 'search'
              "
              (loadMoreArticles)="nextPage()"
              (closeModal)="setViewType('card')"
            ></app-fav-swiper>

            <ng-container *ngIf="searchVisible && articles && articles.length">
              <div class="d-flex justify-content-around px-2 gap-2">
                <button
                  class="btn btn-dark btn-block w-100 prevButton d-block d-sm-none"
                  (click)="prevPage()"
                  [disabled]="page === 1"
                >
                  {{ "PREVIOUS" | translate }}
                </button>
                <button
                  class="btn btn-dark btn-block w-100 nextButton d-block d-sm-none next"
                  (click)="nextPage()"
                  [disabled]="collectionSize < pageSize * (page + 1)"
                >
                  {{ "NEXT" | translate }}
                </button>
              </div>
            </ng-container>
          </ng-container>

          <!-- Preorder -->
          <app-preorder-container
            id="preorderContainer"
            [settings]="searchTabs.preorderSettings"
            class="d-block w-100"
            [ngClass]="checkedUnsaved ? 'danger-preorder' : ''"
            *ngIf="selectedTab === 'preorder'"
            [articles]="articles"
            [type]="preorderType"
            (preorderTypeChanged)="preorderTypeChanged($event)"
          >
            <ng-container *ngIf="searchVisible && articles && articles.length">
              <div class="mx-0 mt-2 row">
                <div class="ps-0 pe-1 col-6">
                  <button
                    class="btn btn-dark btn-block w-100 prevButton d-block d-sm-none"
                    (click)="prevPage()"
                    [disabled]="page === 1"
                  >
                    {{ "PREVIOUS" | translate }}
                  </button>
                </div>
                <div class="ps-0 pe-1 col-6">
                  <button
                    class="btn btn-dark btn-block w-100 nextButton d-block d-sm-none"
                    (click)="nextPage()"
                    [disabled]="collectionSize < pageSize * (page + 1)"
                  >
                    {{ "NEXT" | translate }}
                  </button>
                </div>
              </div>
            </ng-container>
          </app-preorder-container>

          <!-- Image bank -->
          <app-image-bank-article-container
            class="d-block w-100 px-0"
            *ngIf="selectedTab === 'imagebank' && loaded"
            [@pageAnimations]
            (tabSelected)="imageBankTab = $event"
            [articles]="articles"
          >
          </app-image-bank-article-container>

          <!-- No results -->
          <div class="w-100 d-flex align-items-center flex-column">
            <app-no-results *ngIf="loaded && !articles.length"></app-no-results>

            <div
              class="w-100 d-flex align-items-center flex-column"
              *ngIf="
                loaded &&
                !articles.length &&
                filterSearchText === searchText &&
                filterSearchText && filterSearchKeepFilters
              "
            >
              <p>{{ "WITHIN_CURRENT_SELECTION" | translate }}</p>
              <button
                class="btn btn-primary text-center mt-2"
                (click)="resetFilters(sideBar)"
              >
                {{ "SEARCH_ALL_ARTICLES" | translate }}
              </button>
            </div>

            <!-- Suggest terms -->
            <app-suggest-terms
              [hidden]="
                selectedTab === 'preorder' ||
                selectedTab === 'replenishment' ||
                !loaded
              "
              class="m-3 w-100"
              style="max-width: 1000px"
              #suggestions
              [searchTerm]="searchText"
              (getResult)="
                searchText = $event;
                getResults(true, 0);
                suggestions.showTerms = false
              "
            >
            </app-suggest-terms>
          </div>
          <div class="w-100 d-flex justify-content-center">
            <app-promotions-carousel
              *ngIf="loaded && !articles.length && showSearchTabs && showWidgets"
              [@inOutAnimation]
              [hidden]="!showPromotions"
              (widgetsLoaded)="showPromotions = true"
              class="mt-3 companyBg p-4 rounded-5"
              style="max-width: 100%; width: 1000px"
              [autoload]="true"
              ><h2 class="text-white ms-2">
                {{ "ALSO_INTERESTING" | translate }}
              </h2>
            </app-promotions-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="fixedButtonsContainer">
  <button
    class="me-1 rounded btn btn-success addAll"
    (click)="addAll()"
    *ngIf="showAddAllButton"
  >
    {{ "ADD_ALL" | translate }} ({{ selectedForBasketCount }})
  </button>
  <button
    class="me-1 rounded btn btn-dark compareAddAll"
    (click)="compareAddAll()"
    *ngIf="showAddAllButton"
  >
    {{ "COMPARE_ADD_ALL" | translate }} ({{ selectedForBasketCount }})
  </button>
  <button
    class="mt-2 rounded btn btn-danger compareAddAll mt-sm-0"
    (click)="clearAddAll()"
    *ngIf="showAddAllButton"
  >
    {{ "EMPTY" | translate }}
  </button>
  <button
    class="mt-2 rounded btn btn-success btn-lg mt-sm-0"
    style="min-width: 100px"
    (click)="saveReplenishment()"
    *ngIf="selectedTab === 'replenishment' && showSaveReplenishment"
    [ngClass]="{ disabled: replenishmentService.loading }"
    [disabled]="replenishmentService.loading || !replenishmentNotSaved"
  >
    {{ "SAVE" | translate }}
  </button>
</div>
<!-- Article details -->
<app-article-details
  #articleDetails
  *ngIf="!searchVisible"
  (showArticleDetails)="showArticleDetails($event, null)"
  [leaseRequest]="leaseRequest"
>
</app-article-details>

<!-- Paginator -->
<nav
  id="paginator"
  class="navbar navbar-light border-top"
  [ngClass]="[
    !searchVisible && !articles.length ? 'p-0 border-0' : '',
    state === 'fixed' && !staticPaginator ? 'fixed-bottom' : 'static'
  ]"
  *ngIf="(imageBankTab !== 'atmospheric' && articles && articles.length) && showViewPaginator"
  [style.z-index]="searchVisible ? '1028' : '95'"
>
  <div class="container mx-auto mb-0 row">


    <div
      class="ps-0 row justify-content-md-center"
      [ngClass]="searchVisible ? 'd-none' : 'col-md-3'"
    ></div>


    <div
      class="ps-3 pe-0 text-start col-8 col-md-3 row col-xs-12 col-sm-4 numResults"
      [style.display]="searchVisible ? 'block' : 'none'"
    >
      <ng-container *ngIf="loaded || count > 1">
        {{ "PAGE" | translate }}: {{ page }} &#xB7;
        {{ "TOTAL_RESULTS" | translate }}: {{ count
        }}{{ count >= 10000 ? "+" : "" }}
      </ng-container>
    </div>
    <div
      class="px-0 mb-2 col-md-5 col-xs-12 col-sm-12 col-sm-4 mb-md-0 d-md-flex justify-content-md-center"
      [ngClass]="searchVisible ? 'd-none d-sm-flex' : ''"
    >
      <app-article-options
        *ngIf="!searchVisible && articles.length"
        (navigationChange)="navigationChange($event)"
        [selectedIndex]="selectedIndex"
        [page]="page"
        class="d-flex"
      >
      </app-article-options>
      <ngb-pagination
        class="searchPagination"
        [ngClass]="searchVisible ? 'd-none d-sm-block' : 'd-none'"
        *ngIf="collectionSize > 0"
        [collectionSize]="collectionSize"
        [(page)]="page"
        [boundaryLinks]="false"
        [pageSize]="pageSize"
        [maxSize]="8"
        [rotate]="true"
        [boundaryLinks]="true"
        (pageChange)="onPager($event)"
        [boundaryLinks]="true"
        [ellipses]="false"
      >
      </ngb-pagination>
    </div>
    <div class="col-md-1 row col-xs-6 col-sm-4" *ngIf="!searchVisible"></div>
    <div class="px-0 col-md-3 col-xs-6 col-sm-4"></div>
  </div>
</nav>
