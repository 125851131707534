import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ChangeContext, Options } from 'ngx-slider-v2';
import { FilterOption } from 'src/app/interfaces/filter-option';
import { SideBarFilterOption } from 'src/app/interfaces/side-bar-filter-option';
import * as _ from 'underscore';

@Component({
  selector: 'app-range-filter',
  templateUrl: './range-filter.component.html',
  styleUrls: ['./range-filter.component.css'],
})
export class RangeFilterComponent implements OnInit {
  @Input() filter: SideBarFilterOption;
  @Input() multiRange: boolean = true; //two slider handles - min / max values
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  options: Options = null;
  range: Options;
  maxValue: any;
  minValue: any;

  setSliderOptions() {
    // Sort 0-9
    this.filter.options = _.sortBy(this.filter.options, function (o) {
      return o.id;
    });

    let steps = _.sortBy(
      this.filter.options.map((option: FilterOption, index) => {
        return { value: !isNaN(Number(option.id)) ? Number(option.id) : option.id };
      })
    );

    steps = _.sortBy(steps, 'value');

    const options: Options = {
      stepsArray: steps,
      animate: false,
    };
    this.minValue = steps[0].value;
    if (this.multiRange) {
      this.maxValue = steps[steps.length - 1].value;
    }
    this.range = options;
  }

  constructor() {}

  ngOnInit(): void {
    this.setSliderOptions();
  }

  changed(changeContext: ChangeContext): void {
    let parsedValues = {};

    if (!changeContext.value && changeContext.value !== 0) {
      const sliderValue = document.getElementsByClassName("ngx-slider-model-value");
      if (sliderValue && sliderValue.length) {
        parsedValues[sliderValue[0].innerHTML] = {
          checked: true
        }
      }
    } else{
      // If multiple values
      if (changeContext['highValue']) {
        const valuesInRange = _.filter(this.range.stepsArray, function (item) {
          return (
            item.value >= changeContext['value'] &&
            item.value <= changeContext['highValue']
          );
        });
        valuesInRange.forEach((v) => {
          parsedValues[v.value] = {
            checked: true,
          };
        });
      } else {
        parsedValues[changeContext["value"]] = {
          checked: true
        }
      }

    }

    this.change.emit(parsedValues);
  }
}
