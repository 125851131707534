import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { CompanyGroup } from 'src/app/interfaces/company-group';
import { CompanyPromotion } from 'src/app/interfaces/company-promotion';
import { MenuLayout } from 'src/app/interfaces/menu-layout';
import { MenuItem } from 'src/app/interfaces/menuItem';
import { ApplicationService } from 'src/app/services/application.service';
import { SearchService } from 'src/app/services/search.service';
import { Location } from '@angular/common';
import * as _ from 'underscore';
import { HelperService } from 'src/app/services/helper.service';
import {
  trigger,
  transition,
  style,
  animate,
  query,
  stagger,
} from '@angular/animations';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-article-groups-browser',
  templateUrl: './article-groups-browser.component.html',
  styleUrls: ['./article-groups-browser.component.scss'],
  animations: [
    trigger('inAnimation', [
      transition(':enter', [
        style({ opacity: 0.2 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
    ]),
    trigger('pageAnimations', [
      transition(':enter', [
        query(
          '.first, .all',
          [
            style({ opacity: 0, transform: 'translateY(-100px)' }),
            stagger(30, [
              animate(
                '400ms cubic-bezier(0.35, 0, 0.25, 1)',
                style({ opacity: 1, transform: 'none' })
              ),
            ]),
          ],
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ArticleGroupsBrowserComponent implements OnInit {
  @Input() listClass: string = 'list-group-flush';
  @Output()
  menuClickedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  browseGroups: MenuItem[] = [];
  productGroups: MenuItem[] = [];
  subGroups: MenuItem[] = [];
  variationGroups: MenuItem[] = [];
  browseGroupItem: MenuItem;
  productGroupItem: MenuItem;
  subGroupItem: MenuItem;
  variationGroupItem: MenuItem;
  companyGroupsSettings: CompanyGroup;
  subscriptions: any[] = [];
  companyGroupCode: string;
  promotionsLoaded: boolean;
  showPromotions: boolean;
  showPromotionsBikes: boolean;
  hideBrowseGroups: boolean;
  promotions: CompanyPromotion[][] = [];
  isMobile: (userAgent?: string) => boolean;
  showDealerOptions = !this.applicationService.isPartnerPortal();
  promotionPageUrl = {
    ANL: 'CMN_PROM',
    RAL: 'CMN_PROM',
    COM: 'companyPage/ofertas',
    LAP: 'CMN_PROM',
    VAR: 'CMN_PROM',
    WG: 'CMN_PROM',
    GHO: '&HOMEPROMO=ACCOF2&COMPANY=25',
    TUN: 'CMN_PROM',
  };

  constructor(
    public applicationService: ApplicationService,
    private searchService: SearchService,
    private router: Router,
    private deviceDetector: DeviceDetectorService,
    private location: Location,
    private accountService: AccountService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.getMenuItems();
    this.init();
    this.isMobile = this.deviceDetector.isMobile;
    this.showDealerOptions = !this.applicationService.isPartnerPortal();
    this.subscriptions.push(
      this.applicationService.emitChangeCompanyGroupChanged$.subscribe(
        (response) => {
          this.companyGroupCode =
            this.applicationService.getSelectCompanyGroupCode();
          this.init();
        }
      )
    );
  }

  init() {
    this.companyGroupsSettings =
      this.applicationService.getSettingsForCompanyGroup(
        this.applicationService.getSelectCompanyGroupCode()
      ) as CompanyGroup;
  }

  getMenuItems(clear = false) {
    this.browseGroups = [];

    this.applicationService.getMenuItems(clear).then((data: MenuLayout) => {
      this.browseGroups = data.browse_groups;

      // Make subitems alphabetical
      if (this.browseGroups && this.browseGroups.length) {
        for (let articleGroup of this.browseGroups) {
          if (articleGroup.sub_items && articleGroup.sub_items.length) {
            articleGroup.sub_items = _.sortBy(
              articleGroup.sub_items,
              function (i) {
                if (i.description && i.description.length) {
                  return i.description.toLowerCase();
                }
              }
            );
            // Make subsubitems also alphabetical
            for (let subArticleGroup of articleGroup.sub_items) {
              if (
                subArticleGroup.sub_items &&
                subArticleGroup.sub_items.length
              ) {
                subArticleGroup.sub_items = _.sortBy(
                  subArticleGroup.sub_items,
                  function (i) {
                    if (i.description && i.description.length) {
                      return i.description.toLowerCase();
                    }
                  }
                );
              }
            }
          }
        }
      }
    });
  }

  setProductGroup(item: MenuItem) {
    this.productGroups = item.sub_items;
    this.subGroups = [];
    this.variationGroups = [];
    this.showPromotions = false;
  }

  getPromotions() {
    this.promotionsLoaded = false;
    this.showPromotions = true;

    this.searchService.getPromotions().then((apiResponse: ApiResponse) => {
      this.promotionsLoaded = true;

      if (this.helperService.checkResponse(apiResponse)) {
        this.promotions = Object.values(apiResponse.result);
      }
    });
  }

  gotoItem(item: MenuItem) {}

  gotoToPromotion() {
    const promoPage = this.promotionPageUrl[this.applicationService.getSelectCompanyGroupCode()];
    if (promoPage.includes("companyPage")) {
      this.router.navigateByUrl(`/${this.applicationService.getSelectCompanyGroupCode()}/${promoPage}`)
    } else {
      this.gotoToSearch(promoPage)
    }
  }

  gotoToSearch(url?) {
    if (url) {
      this.router.navigateByUrl(
        this.applicationService.getSelectCompanyGroupCode() +
          '/search#filter/COMMON=' +
          url
      );
    } else {
      let url =
        this.applicationService.getSelectCompanyGroupCode() +
        '/search#filter/COMMON=&groups=' +
        this.browseGroupItem.name +
        (this.productGroupItem ? ',' + this.productGroupItem.name : '') +
        (this.subGroupItem ? ',' + this.subGroupItem.name : '') +
        (this.variationGroupItem ? ',' + this.variationGroupItem.name : '');

      const leftItem = this.browseGroups.find((b: MenuItem) => {
        return b.name == this.browseGroupItem.name;
      }) as MenuItem;

      url += `&categories=${this.helperService.urlEncode(
        leftItem.description
      )}`;

      if (this.productGroupItem && typeof leftItem.sub_items !== 'undefined') {
        const centerItem = leftItem.sub_items.find((b: MenuItem) => {
          return b.name == this.productGroupItem.name;
        }) as MenuItem;

        if (centerItem) {
          url += `,${this.helperService.urlEncode(centerItem.description)}`;

          if (this.subGroupItem) {
            const rightItem = centerItem.sub_items.find((b: MenuItem) => {
              return b.name == this.subGroupItem.name;
            }) as MenuItem;
            url += `,${this.helperService.urlEncode(rightItem.description)}`;

            if (this.variationGroupItem) {
              const lowestItem = rightItem.sub_items.find((b: MenuItem) => {
                return b.name == this.variationGroupItem.name;
              }) as MenuItem;
              url += `,${this.helperService.urlEncode(lowestItem.description)}`;
            }
          }
        }
      }

      if (this.router.url.indexOf('/search') >= 0) {
        this.location.go(url);
      } else {
        this.router.navigateByUrl(url);
      }
    }
    this.searchService.setGroups('');
    this.menuClickedEvent.emit(true);
  }

  showBikesPromotions() {
    this.showPromotionsBikes = true;
    this.showPromotions = false;
    this.productGroups = [];
    this.browseGroupItem = null;
  }

  gotoPage(pageId: string) {
    this.router.navigateByUrl(
      `/${this.applicationService.getSelectCompanyGroupCode()}/companyPage/${pageId}`
    );
  }

  showPromoGroups() {
    this.getPromotions();
    this.productGroups = [];
    this.browseGroupItem = null;
    this.showPromotionsBikes = false;
  }
}
