<ng-container *ngIf="shippingAddresses">
  <select *ngIf="type === 'select'" [(ngModel)]="shippingAddresses.selected" [disabled]="shippingAddresses.address_access === 'default'"
    name="shippingAddress" class="form-control" (change)="selectShippingAddress($event)">
    <option *ngIf="showBlank" selected [value]="''">{{"MAKE_A_CHOICE"|translate}}</option>
    <ng-container  *ngFor="let option of shippingAddresses.shipping_addresses">
      <option [value]="option.id" *ngIf="option.company_group_code === applicationService.getSelectCompanyGroupCode() || showAllCompanies">
        {{option.name}} ({{option.address}} {{option.zip}} {{option.city}})
      </option>
    </ng-container>
  </select>
  <div class="d-flex" *ngIf="type === 'dropdown'">
    <div class="d-block w-100 pe-0">
      <div ngbDropdown class="d-inline-block w-100 p-e">
        <button type="button" class="w-100 overflow-hidden btn border bg-white text-start" id="dropdownShipping" ngbDropdownToggle  [disabled]="shippingAddresses.address_access === 'default'">
          <ng-container *ngIf="selectedAddress && !selectBlank">
            <strong class="d-block">{{selectedAddress?.name}}</strong>
            {{selectedAddress?.address}} {{selectedAddress?.zip}} {{selectedAddress?.city}}
          </ng-container>
          <ng-container *ngIf="!selectedAddress || selectBlank">
            <strong class="d-block" id="blankOption">{{"MAKE_A_CHOICE"|translate}}</strong>
          </ng-container>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownShipping">
          <button ngbDropdownItem  *ngIf="showBlank" selected (click)="shippingAddresses.selected = ''; selectShippingAddress('')">{{"MAKE_A_CHOICE"|translate}}</button>
          <ng-container *ngFor="let option of shippingAddresses.shipping_addresses">
            <button ngbDropdownItem  *ngIf="option.company_group_code === applicationService.getSelectCompanyGroupCode() || showAllCompanies" (click)="shippingAddresses.selected = option.id; selectShippingAddress(option)"><strong class="d-block">{{option.name}}</strong>{{option.address}} {{option.zip}} {{option.city}}</button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>

