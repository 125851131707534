import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { Subscription } from "rxjs";
import { ApiResponse } from "src/app/interfaces/api-response";
import { AccountService } from "src/app/services/account.service";
import { AlertService } from "src/app/services/alert.service";
import { ApplicationService } from "src/app/services/application.service";
import { GeneralService } from "src/app/services/general.service";
import { HelperService } from "src/app/services/helper.service";
import { reject } from "underscore";

@Component({
  selector: "app-nda-check",
  templateUrl: "./nda-check.component.html",
  styleUrls: ["./nda-check.component.scss"],
})
export class NdaCheckComponent implements OnInit {
  checked: boolean;
  @Output() checkedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  pageIds = {};
  pageId: number;
  signed: boolean;
  title = "";
  section = "";
  ts = "";
  key = "";
  showCloseButton = true;

  constructor(
    private modal: NgbActiveModal,
    private router: Router,
    private applicationService: ApplicationService,
    private accountService: AccountService,
    private helperService: HelperService,
    private alertService: AlertService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.pageId = this.pageIds[
      this.applicationService.getSelectCompanyGroupCode()
    ];
  }

  @HostListener("scroll", ["$event"])
  onScroll(event: any) {
    // visible height + pixel scrolled >= total height
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 100
    ) {
      this.checked = true;
    }
  }

  checkScrollPosition() {
    setTimeout(() => {
      const el = document.getElementById("scrollBox");
      if (el.offsetHeight + el.scrollTop >= el.scrollHeight) {
        this.checked = true;
      }
    });
  }

  closeModal() {
    window.location.href = `${this.applicationService.getSelectCompanyGroupCode()}/home`;
    this.modal.close();
  }

  submit() {
    this.accountService
      .saveNda(this.section, this.ts)
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          this.checkedEvent.next(true);
          this.modal.close();
        } else {
          this.alertService.showErrorNotification(
            this.translateService.instant("ERROR")
          );
        }
      });
  }
}
