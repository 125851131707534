import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { Group } from "src/app/interfaces/year-agreement-p-and-a";
import { PartBrand } from "src/app/interfaces/yearAgreement/part-brand";
import { Brand } from "src/app/interfaces/yearAgreement/year-agreement-parts";
import { YearAgreementService } from "src/app/services/year-agreement.service";
import { YearAgreementPAndAService } from "src/app/services/year-agreements/year-agreement/year-agreement-p-and-a.service";

@Component({
  selector: "app-dealer-parts-brands-selection",
  templateUrl: "./dealer-parts-brands-selection.component.html",
  styleUrls: ["./dealer-parts-brands-selection.component.scss"],
})
export class DealerPartsBrandsSelectionComponent implements OnInit {
  @Output() brandsChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() groupId: number;
  @Input() group: Group;
  @Input() label1;
  @Input() label2;
  @Input() label3;
  @Input() label4;
  @Input() showTier: boolean;
  @Input() type;
  @Input() brands: Brand[];
  @Input() bonusOptions: Brand[];
  @Input() showToggle: boolean;
  @Input() metric: string;
  @Input() showTarget: boolean;
  @Input() showDiscount: boolean;
  @Input() exactSelectionRequired: boolean;
  @Input() discountEditable: boolean;
  @Input() targetEditable: boolean;
  @Input() hasMaxBrandSelection: boolean;
  @Input() hasPlanning: boolean;
  @Input() title: string;
  @Input() rows: number = 1;
  valid: boolean;
  brandChangeEvent: Subscription;
  constructor(
    public yearAgreementService: YearAgreementService,
    public yearAgreementPAndAService: YearAgreementPAndAService
  ) {}

  ngOnInit() {
    this.getSelection();
    this.brandChangeEvent =
      this.yearAgreementPAndAService.brandChangeEmitted$.subscribe((data) => {
        if (data.groupId !== this.groupId) {
          this.brands.forEach((b: Brand) => {
            if (b.id === data.brandId) {
              b.selected = false;
              this.checkSelection();
              this.brandsChange.emit();
              this.setValues(b);
            }
          });
        }
      });
  }

  ngOnDestroy(): void {
    if (typeof this.brandChangeEvent !== "undefined") {
    this.brandChangeEvent.unsubscribe();
    }
  }

  getSelection() {
    let selection = [];
    if (this.brands && this.brands.length) {
      selection = this.brands.filter((b) => {
        this.setValues(b);
        return b.selected;
      });
    }
    return selection;
  }

  checkSelection() {
    if (this.exactSelectionRequired) {
      const selection = this.getSelection();
      this.valid =
        selection.length <=
        this.yearAgreementPAndAService.maxPartBrandSelection;
    }

  }

  matchesSelection() {
    const selection = this.getSelection();
    return (
      selection.length === this.yearAgreementPAndAService.maxPartBrandSelection
    );
  }

  connectedBrandSelected(brand: string): boolean {
    const isChecked = this.brands.filter((b: Brand) => {
      return b.id === brand && b.selected;
    });
    return isChecked.length ? true : false;
  }

  setValues(brand: Brand, disablePrevious?: false, type: string = 'regular') {

    const options = type === "regular" ? this.brands : this.bonusOptions;

    if (this.metric === "singleselect") {
      options.forEach((b: Brand) => {
        if (b.id !== brand.id && disablePrevious) {
          b.selected = false;
        }
      })
    }

    if ((!brand.selected || !brand.tier) && brand.tiers) {
      brand.target = null;
      brand.value = null;
      brand.discount = null;
      brand.tier = null;
      brand.planning = false;
    }

    if (!brand.selected) {
      brand.planning = false;
    }

    if (brand.child_id) {
      const connected_brand = options.find((b: Brand) => {
        return b.id === brand.child_id;
      });
      if (connected_brand) {
        connected_brand.selected = brand.selected;
        connected_brand.tier = brand.tier;
        this.setValues(connected_brand);
      }
    }

    if (brand.tiers && brand.tiers.length > 1) {
      brand.tiers.find((t) => {
        if (t.value == brand.tier && brand.selected) {
          brand.target = t.target;
          brand.value = t.value;
          brand.discount = t.discount;
          return true;
        }
      });
    } else {
      if (brand.tiers && brand.tiers.length === 1) {
        if (brand.selected) {
          brand.discount = brand.tiers[0].discount;
          brand.tier = brand.tiers[0].value;
        }
      } else {
        if (this.yearAgreementPAndAService.agreementModel
          .selected_bike_dealer_ship) {
        brand.discount =
          brand[
            this.yearAgreementPAndAService.agreementModel
              .selected_bike_dealer_ship === "Premium"
              ? "premium"
              : "parts_only"
          ][
            this.yearAgreementPAndAService.agreementModel.selected_dealer_level
          ];
        }
      }
    }
  }

  triggerChange(brand: Brand) {
    if (brand.selected) {
      this.yearAgreementPAndAService.brandSelected(brand.id, this.groupId);
    }
  }

  // Check if brand is selected on the left side (dealer sells brand). Otherwise no Exception target can be made
  isSelected(brand: Brand) {
    let isSelected = false;
    isSelected =
      this.yearAgreementService.yearAgreementParts.dealer_ships.group_1
        .concat(
          this.yearAgreementService.yearAgreementParts.dealer_ships.group_2
        )
        .concat(
          this.yearAgreementService.yearAgreementParts.dealer_ships.group_3
        )
        .findIndex((b) => {
          return b.selected && b.description === brand.description;
        }) >= 0;

    if (!isSelected) {
      brand.value = null;
    }

    return isSelected;
  }

  setPlanning(brand: Brand) {
    const selectedTier = brand.tiers.find((t) => {
      return t.value === Number(brand.tier);
    });
    if (selectedTier) {
      brand.has_planning = selectedTier.has_planning;
    }
  }

   divideSortedArray(arr) {
    const length = arr.length;
    const midpoint = Math.ceil(length / 2);

    const firstArray = arr.slice(0, midpoint);
    const secondArray = arr.slice(midpoint);

    return [firstArray, secondArray];
  }

  getBrands(brands: Array<Brand>, rowPosition: number) {
    if (this.rows === 2) {

      const [firstHalf, secondHalf] = this.divideSortedArray(brands);

      if (rowPosition === 1) {
        return firstHalf;
      }
      if (rowPosition === 2) {
        return secondHalf;
      }
    } else {
      return this.brands;
    }

  }
}
