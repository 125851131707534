import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from "@angular/core";
import { Article } from "src/app/interfaces/article";
import {
  ArticleType,
  AvailableArticleTypes
} from "src/app/interfaces/article-type";
import { ApplicationService } from "src/app/services/application.service";

@Component({
  selector: "app-delivery-week",
  templateUrl: "./delivery-week.component.html",
  styleUrls: ["./delivery-week.component.scss"]
})
export class DeliveryWeekComponent implements OnInit {
  @Output() weekSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() article: Article;
  @Input() deliveryWeek: number;
  @Input() deliveryYear: number;
  @Input() lockedDeliveryWeeks: boolean; // not allowed to change delivery week
  @Input() forceDeliveryWeekSelect: boolean; // always show delivery week
  articleType: typeof AvailableArticleTypes = AvailableArticleTypes;
  parsedDeliveryWeek: string;
  showDealerOptions = !this.applicationService.isPartnerPortal();
  constructor(private applicationService: ApplicationService) {}

  ngOnInit() {
  }

  parseWeek() {
    this.parsedDeliveryWeek =
      this.article.delivery_year +
      ((String(this.article.delivery_week).length === 1 ? "0" : "") +
        String(this.article.delivery_week));
  }

  ngOnChanges(changes: SimpleChanges) {
    this.parseWeek();
  }

  changeDeliveryWeek(data) {
    this.weekSelected.next(data);
  }
}
