<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ "COUPON_CODES" | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    </button>
</div>
<form ngNativeValidate (submit)="showSaveButton ? saveCoupons() : undefined">
  <div class="modal-body">
    <p>
      {{ "COUPON_EXPLAINED" | translate }}
    </p>
    <div class="alert alert-info" role="alert">
      {{ "COUPONS_BIKE_PARTS_NOTICE" | translate }}
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col" class="px-2">{{ "COUPON_AMOUNT" | translate }}</th>
          <th scope="col" class="px-2">{{ "COUPON_AVAILABLE" | translate }}</th>
          <th scope="col" class="px-2">{{ "COUPON_USE" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let coupon of coupons">
          <td
            scope="row"
            [innerHTML]="
              coupon.value.value
                | currencyConvert: coupon.value.currency
            "
          ></td>
          <td>{{ coupon.quantity }}</td>
          <td>
            <input
              type="number"
              class="form-control"
              [max]="coupon.quantity"
              [min]="0"
              [name]="coupon.coupon_code"
              [(ngModel)]="coupon.coupon_quantity_selected"
              [value]="coupon.coupon_quantity_selected"
              (change)="
                calcTotalCouponValue() || checkIfTotalCouponValueTooHigh(coupon)
              "
              oninput="validity.valid || (value='0');"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row mb-3">
      <div class="col-6">
        {{ "SUBTOTAL_BIKE_PARTS" | translate }}
        <span
          class="fw-bold d-block"
          [innerHTML]="sum | currencyConvert: currency"
        ></span>
      </div>
      <div class="col-6 text-end">
        {{ "APPLIED_COUPON_VALUE" | translate }}:
        <span
          [style.color]="totalCouponValueTooHigh ? 'red' : 'initial'"
          class="fw-bold d-block"
          [innerHTML]="total_coupon_amount | currencyConvert: currency"
        ></span>
      </div>
    </div>

    <div
      [style.visibility]="totalCouponValueTooHigh ? 'visible' : 'hidden'"
      class="alert alert-warning"
    >
      {{ "COUPONS_TOO_HIGH" | translate }}
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss('Cross click')"
    >
      {{ "CANCEL_ACTION" | translate }}
    </button>
    <input
      type="submit"
      class="btn btn-primary"
      [value]="'SAVE_COUPONS' | translate"
      [disabled]="totalCouponValueTooHigh"
      *ngIf="showSaveButton"
    />
    <p *ngIf="!showSaveButton">{{"COUPONS.SAVE_AFTER_PROMO_PERIOD"|translate}}</p>
  </div>
</form>
