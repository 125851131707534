import { Component, OnInit, ViewChild } from "@angular/core";
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { ApplicationService } from "src/app/services/application.service";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BasketService } from "src/app/services/basket.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { Basket } from "src/app/interfaces/basket";
import { GroupedBasketArticles } from "src/app/interfaces/grouped-basket-articles";
import { Location } from "@angular/common";
import { BasketAction } from "src/app/interfaces/basket-action";
import { Subscription } from "rxjs";
import { GroupedBasketArticlesByMonth } from "src/app/interfaces/grouped-basket-articles-by-month";
import { TrackingService } from "src/app/services/tracking.service";
import { BasketArticles } from "../../../interfaces/basket-articles";
import { Coupon } from "src/app/interfaces/coupon";
import { AlertService } from "src/app/services/alert.service";
import { BasketArticle } from "src/app/interfaces/basketArticle";
import * as _ from "underscore";
import { PreorderSettings, PreorderSettingsContainer } from "src/app/interfaces/settings/preorder-settings";
import { AccountService } from "src/app/services/account.service";
import { AccountInformation } from "src/app/interfaces/account-information";
import { PaymentService } from "src/app/services/payment.service";
import { PreorderService } from "src/app/services/preorder.service";
import { NgbModal, NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmDialogComponent } from "../../modal-components/confirm-dialog/confirm-dialog.component";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.scss"],
})
export class CheckoutComponent implements OnInit {
  @ViewChild("tooltip_checkout") tooltip: NgbTooltip;
  private _subscription: Subscription;
  basket: Basket;
  clicked = false;
  groupedArticles: GroupedBasketArticles;
  groupedArticlesByMonth: GroupedBasketArticlesByMonth;
  groupedArticlesByDirectDelivery: BasketArticles;
  unOrderedArticles: GroupedBasketArticles;
  breadcrumbs: any = [];
  basketSum: any = {
    parts: 0,
    bicycles: 0,
    total: 0,
  };
  basketSumMonths: any = {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0,
  };
  basketSumDirect: {};
  basketId = 0;
  showroomMode = false;
  ordered = false;
  totalMonths: number[];
  companyGroupCode: string;
  coupons: Coupon[] = [];
  coupon: Coupon;
  total_coupon_discount: number = 0;
  individualPreorderCheckout = true; //WG - DACH
  couponsExist: boolean = false;
  groupId: string = "all";
  preorderSettings: PreorderSettings;
  onlinePaymentRestrictionLevel: number;
  paymentUrl: string = "";
  preorderType: string = "Bicycles";
  loaded = false;
  shippingDateParts: "";
  shippingDateBicycles: "";
  isHelpdesk: boolean;
  stickyWidth: number;
  fixedNavBar = true;
  showNavbar: boolean;
  orderedBasket: Basket;

  get validShippingDates() {
    if (!this.groupedArticles && !this.basket.pre_order) {
      return false;
    }

    if (!this.basket.shipping_costs_mandatory) {
      return true;
    } else {
      if (
        (this.shippingDateBicycles ||
          !this.groupedArticles.bicycles.items.length) &&
        (this.shippingDateParts || !this.groupedArticles.parts.items.length)
      ) {
        return true;
      }
    }
    return true;
  }

  get showPaymentButton() {
    return (
      !this.ordered &&
      (this.onlinePaymentRestrictionLevel === 1 ||
        this.onlinePaymentRestrictionLevel === 2) &&
      ["COM", "RAL","ANL"].includes(
        this.applicationService.getSelectCompanyGroupCode()
      )
    );
  }

  // Baskets with both parts and bicycles cannot be paid - when Raleigh
  get paymentAllowed() {
    return !(
      this.companyGroupCode === "RAL" && this.groupedArticles?.parts?.items?.length && this.groupedArticles?.bicycles?.items?.length
    );
  }

  constructor(
    public applicationService: ApplicationService,
    private translateService: TranslateService,
    private basketService: BasketService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private trackingService: TrackingService,
    private alertService: AlertService,
    private accountService: AccountService,
    private paymentService: PaymentService,
    private preorderService: PreorderService,
    private modalService: NgbModal,
    private helperService: HelperService,
  ) {
    this.getShowroomMode();
    this.companyGroupCode = this.applicationService.getSelectCompanyGroupCode();
    this.totalMonths = this.applicationService.getMonths();
  }

  getShowroomMode() {
    this.showroomMode = this.applicationService.getShowroomMode(
      this.showroomMode
    );
    this.fixedNavBar = localStorage.getItem("navbar-not-fixed") === "1" ? false : true;
  }

  ngOnInit() {
    this.isHelpdesk = this.accountService.isHelpdesk();
    this.getAccountInformation().then(() => {
      this.route.params.subscribe((params) => {
        if (params["period"]) {
          this.groupId = params["period"];
        }
        if (params["id"]) {
          this.basketId = params["id"];
          // Don't get checkout basket when on payment-success page
          if (params["id"] !== "payment-success") {
            this.getCheckoutBasket();
          }
        }
      });

      this._subscription = this.basketService.changeEmitted$.subscribe(
        (action: BasketAction) => {
          if (
            this.basket &&
            this.basket.items &&
            action.type === "updateCoupon"
          ) {
            this.total_coupon_discount = action.value;
            this.calcTotal(this.basket.items);
          }

          if (this.basket && this.basket.locked && action.type === "unlocked") {
            this.basket.locked = false;
          }

          if (
            this.basket &&
            this.basket.id &&
            String(action.basketId) === String(this.basket.id)
          ) {
            this.getCheckoutBasket();
          }
        }
      );

      this.applicationService.changeEmitted$.subscribe((response) => {
        this.getShowroomMode();
        if (this.basket) {
          this.calcTotal(this.basket.items);
        }
      });

      // redirect url
      if (window.location.href.includes("checkout/payment-success")) {
        this.router.navigateByUrl(
          `${this.companyGroupCode}/checkout/payment/payment-success`
        );
      }
    });
  }

  getPreorderSettings() {
    return new Promise((resolve) => {
      this.preorderService
        .getPreorderSettingsOfType(this.preorderType)
        .then((response: PreorderSettings) => {
          this.preorderSettings = response;
          if (response) {
            this.individualPreorderCheckout =
              this.preorderSettings.use_time_slots &&
              (this.companyGroupCode === "WG" ||
                this.companyGroupCode === "GHO");
            resolve(true);
          }
        });
    });
  }

  ngOnDestroy() {
    if (typeof this._subscription !== "undefined") {
      this._subscription.unsubscribe();
    }
  }

  addBreadCrumbs() {
    this.breadcrumbs = [
      new Breadcrumb(
        this.translateService.instant("HOME"),
        this.applicationService.getSelectCompanyGroupCode() + "/"
      ),
      new Breadcrumb(
        this.translateService.instant("BASKET"),
        this.applicationService.getSelectCompanyGroupCode() +
          (this.basket.pre_order ? `/preorderBasket/${this.basket.basket_type === "PreOrderBikes" || this.basket.preorder_type === "Bicycles" ?"Bikes":"Parts"}/` : "/basket/") +
          this.basketId
      ),
      new Breadcrumb(this.translateService.instant("CHECKOUT"), "#"),
    ];
  }

  getCheckoutBasket() {
    this.loaded = false;
    this.applicationService.showLoader(true);
    this.basketService
      .getCheckoutBasket(this.basketId)
      .subscribe((response: ApiResponse) => {
        if (this.helperService.checkResponse(response)) {
          this.applicationService.hideLoader();
          this.loaded = true;
          this.basket = response.result;
          this.addBreadCrumbs();

          if (this.basketService.basketHasBeenOrdered(this.basket)) {
            this.router.navigateByUrl(
              `${this.companyGroupCode}/order/${this.basket.id}`
            );
          } else {
            this.getPreorderSettings().then(() => {
              this.parseBasket();
            });
          }
        } else {
          this.router.navigateByUrl(`/${this.applicationService.getSelectCompanyGroupCode()}/basket/${this.basketId}`);
        }
      });
  }

  parseBasket() {
    if (
      this.basket &&
      !this.basket.pre_order &&
      this.basket.items &&
      this.basket.items.length
    ) {
      this.coupons = typeof this.basket.allotted_coupons !== "undefined" ? [...this.basket.allotted_coupons] as Coupon[] : [];
      this.groupedArticles = this.basketService.groupArticles(
        this.basket.items
      );
    }

    //this.checkMandatory();
    //this.checkBlocking();

    if (this.basket && this.basket.items && this.basket.pre_order) {
      this.groupedArticlesByDirectDelivery =
        this.basketService.groupArticlesByDirectDelivery(this.basket.items);
      this.groupedArticlesByMonth = this.basketService.groupArticlesByMonth(
        this.basket.items
      );
    }

    if (this.basket && this.basket.items) {
      this.trackingService.checkout(this.basket.items);
      this.calcTotal(this.basket.items);
    }

    if (
      this.basket &&
      !this.basket.ordering_allowed &&
      typeof this.tooltip !== "undefined"
    ) {
      setTimeout(() => {
        this.tooltip.open();
      }, 0);
    }
    this.getTotalWidth();
  }

  checkMandatory() {
    const hasWarning = this.basket.items.find((b: BasketArticle) => {
      return b.warning ? true : false
    })
    if (hasWarning) {
      const modalRef = this.modalService.open(ConfirmDialogComponent, {
        centered: true,
        size: "sm",
        windowClass: "confirmDialog",
      });
      modalRef.componentInstance.isConfirm = true;
      modalRef.componentInstance.title = this.translateService.instant("NOTE");
      modalRef.componentInstance.body = this.translateService.instant("BICYCLE_BATTERY_WARNING");
      modalRef.componentInstance.confirmClicked.subscribe(() => {
        this.back()
      });
    }
  }

  calcTotal(articles) {
    this.basketSum = this.basketService.calcTotal(
      articles,
      "basket",
      this.showroomMode,
      this.basket.shipping_cost,
      "regular",
      this.total_coupon_discount
    );
    this.basketSumMonths = this.basketService.calcTotal(
      articles,
      "basket",
      this.showroomMode,
      this.basket.shipping_cost,
      "preorder"
    );
    this.basketSumDirect = this.basketService.calcTotal(
      articles,
      "basket",
      this.showroomMode,
      this.basket.shipping_cost,
      "direct"
    );
    this.showNavbar = true;
  }

  back() {
    const url =
      this.applicationService.getSelectCompanyGroupCode() +
      (this.basket.pre_order ? `/preorderBasket/${this.basket.basket_type === "PreOrderBikes" || this.basket.preorder_type === "Bicycles" ?"Bikes":"Parts"}/` : (this.applicationService.isPartnerPortal() ? `/shipping/` : "/basket/")) + this.basket.id

    this.router.navigateByUrl(url);
  }

  paycond() {
    const url =
      this.applicationService.getSelectCompanyGroupCode() +
      ("/paycond/" + this.basket.id + "/" + this.groupId + `?type=${this.basket.basket_type === "PreOrderBikes" || this.basket.preorder_type === "Bicycles" ?"Bikes":"Parts"}`);
    const status4articles = document.getElementsByClassName("status-4");
    const status5articles = document.getElementsByClassName("status-5");
    if ((!status5articles.length && !status4articles.length) || this.applicationService.getSelectCompanyGroupCode() !== 'WG') {
      this.router.navigateByUrl(url);
    } else {
      const modalRef = this.modalService.open(ConfirmDialogComponent, {
        centered: true,
        size: "sm",
        windowClass: "confirmDialog",
      });
      modalRef.componentInstance.isConfirm = true;
      modalRef.componentInstance.title = this.translateService.instant("NOTE");
      modalRef.componentInstance.body = this.translateService.instant("PRE_ORDER_ARTICLE_UNAVAILABLE_REMOVE_NOTICE");
      modalRef.componentInstance.confirmClicked.subscribe(() => {
        this.router.navigateByUrl(url);
      });
    }
  }

  getTotalWidth() {
    setTimeout(() => {
      const stickyElement = document.getElementById("sticky")
      if (stickyElement) {

        // Get the width of the element after it's been rendered.
        this.stickyWidth = stickyElement.offsetWidth;
      }
    }, 0);
  }


  checkout() {
    this.clicked = true;
    this.applicationService.showLoader(true);
    this.loaded = false;
    this.basketService
      .orderBasket(
        this.basketId,
        this.shippingDateBicycles,
        this.shippingDateParts
      )
      .subscribe((response: ApiResponse) => {
        this.loaded = true;
        this.applicationService.hideLoader();
        if (response.success) {
          this.ordered = true;
          this.orderedBasket = response.result;


          this.alertService.showPopup(
            this.translateService.instant("ORDER_SENT_SUCCESS")
          );

          // Remove stored ordered baskets
          sessionStorage.removeItem("baskets");

          // Track purchase
          this.trackingService.purchase(
            String(this.basketId),
            this.companyGroupCode,
            this.basket.items,
            this.basketSum["totalWithShipping"],
            String(
              this.basket.shipping_cost ? this.basket.shipping_cost.value : 0
            )
          );

          if (response.result["not_ordererd_items"]) {
            this.unOrderedArticles = this.basketService.groupArticles(
              response.result["not_ordererd_items"]
            );
          }
        } else {
          this.alertService.showPopup(
            response.result
              ? response.result
              : this.translateService.instant("ERROR"),
            "error"
          );
        }

        this.location.go(
          this.applicationService.getSelectCompanyGroupCode() +
            "/order/" +
            this.basketId +
            "?processed=true"
        );
      });
  }

  unlockBasket() {
    this.basketService.unlockBasket(this.basketId);
  }

  private getAccountInformation() {
    return this.accountService
      .getAccountInformation(false)
      .then((accountInformation: AccountInformation) => {
        this.onlinePaymentRestrictionLevel =
          accountInformation.online_payment_restriction_level[
            this.companyGroupCode
          ] || 0;
      });
  }

  goToPaymentUrl() {
    this.loaded = false;
    if (!this.accountService.isHelpdesk()) {
      this.paymentService
        .getCheckoutPaymentUrl(
          this.basketId,
          this.shippingDateBicycles,
          this.shippingDateParts
        )
        .subscribe((response: ApiResponse) => {
          this.loaded = true;
          this.paymentUrl = response.result;
          if (response.success) {
            window.location.href = this.paymentUrl;
          } else {
            // Needs to be diffrent error message maybe
            this.alertService.showErrorNotification(response.result);
          }
        });
    } else {
      alert(this.translateService.instant("PAYMENT_NOT_ALLOWED_HELPDESK"));
    }
  }
}
