<div class="ps-3 articleContainer row h-100">

  <h4 class="ps-0 mb-2 col-md-12" *ngIf="mainArticle.cross_sell_ecommerce && showHeader">
    {{header}}
  </h4>

  <div class="row w-100 pe-0 ps-2" *ngIf="!onlyMandatory">
    <div class="mx-auto spinner-border text-primary" role="status" *ngIf="!loaded">
      <span class="visually-hidden">Loading...</span>
    </div>
    <!-- Batteries -->
    <ng-container *ngIf="loaded && batteryArticles && batteryArticles.length && type === 'crosssell'">
      <div class="px-0 mx-0 col-md-12 row requiredArticles">
        <h5 class="ps-0 col-md-12 d-inline-flex align-items-center gap-2">{{'BATTERIES_AND_CHARGERS'|translate}}
          <span *ngIf="mainArticle.mandatory_battery"> <span class="badge bg-dark fw-bold d-inline-flex align-items-center"><span class="text-decoration-underline">{{ 'MANDATORY_BATTERY' | translate }}</span></span></span>
        </h5>
        <ng-container *ngFor="let article of batteryArticles">
          <div class="pb-2 ps-0 gap-2 pr-0 d-flex col-xs-12 col-sm-6 align-items-stretch"
            [ngClass]="!compact ? (batteryArticles.length > 1 ? 'col-xl-6 col-md-6 col-lg-6' : 'col-xl-12 col-md-12 col-lg-12') : 'col-12 col-md-4'">
            <div class="px-0 col-md-12 article position-relative"
              [ngClass]="[shopOption ? 'mb-3' : '', article.selected ? 'selected' : '']">
              <app-article-card class="d-flex col-lg-12 h-100" [ngClass]="shopOption ? 'shopOption' : ''"
                [article]="article" [useLazyLoad]="false" [animate]="true" [hideOverlay]="hideArticleOverlay"  [gotoDetailsPage]="false"
                (articleSelected)="articleSelected($event)">
                <div footer class="col-12 buttons border" *ngIf="shopOption && article.single_article_code" [ngStyle]="{'height': !showQuantity ? '40px' : 'auto'}">
                  <input type="number" placeholder="0" class=" mx-2 my-2 form-control form-control-sm d-inline" size="5" *ngIf="showQuantity"
                    [max]="mainArticle.mandatory_battery ? quantityMainArticle : 999" [(ngModel)]="article.quantity"
                    (change)="amountChanged($event, article)">

                  <div class="mt-2 form-check form-switch d-inline-block ms-2">
                    <input type="checkbox" class="form-check-input" [id]="article.id"
                      (click)="articleChecked($event, article, mainArticle.mandatory_battery)"
                      [(ngModel)]="article.selected">
                    <label class="form-check-label" [for]="article.id"></label>
                  </div>
                </div>
                <span buttons class="p-2 col-12 buttons border position-static d-block" *ngIf="modifyFromBasket && article.single_article_code">
                  <button class="btn btn-primary" (click)="articleSelected(article)">{{"SELECT"|translate}}</button>
                </span>
                <span footer class="p-2 mt-2 col-12 buttons border position-static d-block" *ngIf="modifyFromBasket && article.single_article_code">
                  <button class="btn btn-primary" (click)="articleSelected(article)">{{"SELECT"|translate}}</button>
                </span>
              </app-article-card>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <!-- CrossSell -->
    <ng-container *ngIf="loaded && articles && articles.length; else noResults">
      <div class="px-0 mx-0 mt-2 mb-4 col-md-12 row regularArticles">
        <h4 class="ps-0 mb-2 col-md-12" *ngIf="showHeader && header !== ('CROSS_SELL_HEADER'|translate)">
          {{"CROSS_SELL_HEADER"|translate}}
        </h4>

        <ng-container *ngFor="let article of articles">
          <div class="pb-2 mb-1 ps-0 pe-0 pe-sm-3 d-flex col-xs-12 col-sm-6 align-items-stretch"
            [ngClass]="articles.length > 1 ? 'col-xl-6 col-md-6 col-lg-6' : 'col-xl-12 col-md-12 col-lg-12'">
            <div class="px-0 col-md-12 article position-relative w-100"
              [ngClass]="[shopOption ? 'mb-3' : '', article.selected ? 'selected' : '']">
              <app-article-card class="d-flex col-lg-12 h-100" [ngClass]="shopOption ? 'shopOption' : ''"
                [article]="article" [useLazyLoad]="false" [animate]="true" [gotoDetailsPage]="false"
                (articleSelected)="articleSelected($event)">
                <span footer class="col-12 buttons border" *ngIf="shopOption && article.single_article_code">
                  <input type="number" placeholder="0" class=" mx-2 my-2 form-control form-control-sm d-inline" size="5"
                    [(ngModel)]="article.quantity" (change)="amountChanged($event, article)">
                  <div class="mt-2 form-check form-switch d-inline-block">
                    <input type="checkbox" class="form-check-input" [id]="article.id"
                      (click)="articleChecked($event, article, false)" [(ngModel)]="article.selected">
                    <label class="form-check-label" [for]="article.id"></label>
                  </div>
                </span>
              </app-article-card>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <button class="mt-2 btn btn-primary d-block" (click)="compareArticles()" style="order: 3"
      *ngIf="type === 'alternatives' && articles.length && !onlyMandatory">
      {{'COMPARE' | translate}}</button>
  </div>
  <div class="mx-0 row w-100 ps-2" *ngIf="onlyMandatory && batteryArticles && batteryArticles.length">
    <div class="mx-auto spinner-border text-primary" role="status" *ngIf="!loaded">
      <span class="visually-hidden">Loading...</span>
    </div>
    <app-preorder-container class="w-100" [articles]="batteryArticles" [maxQuantity]="quantityMainArticle" (qtyChanged)="qtyChanged.emit($event)" [showButtons]="false" [hideSaveButton]="false" [basketArticleId]="basketArticleId"></app-preorder-container>
  </div>
  <ng-template #noResults>
    <p *ngIf="loaded && !batteryArticles">
      <ng-container>
        {{'NO_RESULTS'|translate}}
      </ng-container>
    </p>
  </ng-template>
</div>
