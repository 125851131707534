import { Component, OnInit, Input } from "@angular/core";
import * as _ from "underscore";
import { AvailableAgreementTypes } from "src/app/interfaces/yearAgreement/available-agreement-types";
import { YearAgreementDataModel } from "src/app/interfaces/yearAgreement/year-agreement-data-model";
import { TranslateService } from "@ngx-translate/core";
import { DashboardService } from "src/app/services/dashboard.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import {
  DashboardData,
  Group,
} from "src/app/interfaces/dealerDashboard/dashboard-data";

@Component({
  selector: "app-purchase-distribution",
  templateUrl: "./purchase-distribution.component.html",
  styleUrls: ["./purchase-distribution.component.scss"],
})
export class PurchaseDistributionComponent implements OnInit {
  @Input() yearAgreementModel: YearAgreementDataModel;
  @Input() overviewType: string = "distribution";
  @Input() tab: string;
  planningPercentage = 70;
  turnOverFields = {
    brands: "TurnoverBrands",
    sports: "TurnoverSports",
    specials: "TurnoverSpecials",
  };
  sections = {
    brands: "BRANDS_YEARAGREEMENT",
    sports: "SPORTS",
    specials: "SPECIALS"
  }
  totalSum = 0;
  backorderData: Group[];

  constructor(
    private translateService: TranslateService,
    private dashboardService: DashboardService
  ) {}

  ngOnInit() {

    // Todo misschien vanuit backend bepalen?
    if (this.yearAgreementModel.year >= 2024) {
      this.planningPercentage = 30;
    }

    if (this.overviewType === 'planning') {
      this.getBackOrder();
    }
  }

  calcTotal() {
    let totalSum = 0;
    [
      AvailableAgreementTypes.Brands,
      AvailableAgreementTypes.Specials,
      AvailableAgreementTypes.Sports,
    ].forEach((type) => {
      let sum = _.reduce(
        Object.values(this.yearAgreementModel[type]["turnover_selection"]),
        function (memo: number, num: number) {
          return memo + num;
        },
        0
      );
      totalSum += sum;
    });
    this.totalSum = totalSum;
  }

  editSum(type: string) {
    const total = this.yearAgreementModel[type]["selected"][
      this.turnOverFields[type]
    ];
    var turnover = prompt(
      this.translateService.instant("EDIT_TURNOVER"),
      total.toString()
    );
    if (turnover != null) {
      this.yearAgreementModel[type].manualTurnover = Number(turnover);
    }
  }

  getBackOrder() {
    this.dashboardService
      .getDashboardData()
      .subscribe((apiResponse: ApiResponse) => {
        if (apiResponse && apiResponse.result) {
          const data: DashboardData = apiResponse.result;
          this.backorderData = data.groups;
        }
      });
  }
}
