import { Component, Input, OnInit } from "@angular/core";
import { BasketService } from "src/app/services/basket.service";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationService } from "src/app/services/application.service";
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { ActivatedRoute, Router } from "@angular/router";
import { Basket } from "src/app/interfaces/basket";
import { Subscription } from "rxjs";
import { HelperService } from "src/app/services/helper.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import {
  trigger,
  transition,
  query,
  style,
  stagger,
  animate,
} from "@angular/animations";

@Component({
  selector: "app-baskets",
  templateUrl: "./baskets.component.html",
  styleUrls: ["./baskets.component.scss"],
  animations: [
    trigger("pageAnimations", [
      transition(":enter", [
        query(
          ".basket-item",
          [
            style({ opacity: 0, transform: "translateY(100px)" }),
            stagger(30, [
              animate(
                "400ms cubic-bezier(0.35, 0, 0.25, 1)",
                style({ opacity: 1, transform: "none" })
              ),
            ]),
          ],
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class BasketsComponent implements OnInit {
  baskets = [];
  breadcrumbs: any = [];
  @Input() type = "open";
  @Input() basePage = "baskets";
  @Input() hideHeader = false;
  loaded = false;
  xhr: Subscription;
  openBaskets: Basket[];
  sentBaskets: Basket[];

  get basketStorageKey() {
    return `baskets-${this.applicationService.getSelectCompanyGroupCode()}-${this.applicationService.isV2Basket()?'v2':'v1'}`;
  }

  constructor(
    private router: ActivatedRoute,
    private basketService: BasketService,
    private translateService: TranslateService,
    private applicationService: ApplicationService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    this.breadcrumbs = [
      new Breadcrumb(
        this.translateService.instant("HOME"),
        this.applicationService.getSelectCompanyGroupCode() + "/home"
      ),
      new Breadcrumb(this.translateService.instant("ORDER"), "#"),
      new Breadcrumb(this.translateService.instant("BASKETS"), "#"),
    ];
    this.type = this.router.snapshot.data["type"] ?? this.type;

    this.getBaskets();
  }

  getBaskets() {
    this.loaded = false;
    switch (this.type) {
      case "open":
        this.getOpenBaskets();
        break;
      case "sent":
        this.getSentBaskets();
        break;
      case "archive":
        this.getDeletedBaskets();
        break;
    }
  }

  getOpenBaskets(reload?: boolean) {
    if (this.openBaskets && !reload) {
      this.baskets = this.openBaskets;
      this.loaded = true;
      this.applicationService.hideLoader();
    } else {
      this.applicationService.showLoader(true);
      this.xhr = this.basketService
        .getBaskets()
        .subscribe((response: ApiResponse) => {
          if (this.helperService.checkResponse(response)) {
            this.loaded = true;
            this.applicationService.hideLoader();
            this.baskets = response.result;
            this.openBaskets = response.result;
          }
        });
    }
  }

  getSentBaskets() {
    if (this.getStoredBaskets()) {
      this.sentBaskets = this.getStoredBaskets();
    }
    if (this.sentBaskets) {
      this.baskets = this.sentBaskets;
      this.applicationService.hideLoader();
      this.loaded = true;
    } else {
      this.xhr = this.basketService
        .getOrderedBaskets()
        .subscribe((response: ApiResponse) => {
          if (this.helperService.checkResponse(response)) {
            this.loaded = true;
            this.applicationService.hideLoader();
            this.baskets = response.result;
            this.sentBaskets = response.result;
            this.storeBaskets();
          }
        });
    }
  }

  getStoredBaskets() {
    if (sessionStorage.getItem(this.basketStorageKey)) {
      let baskets = null;
      try {
        baskets = JSON.parse(sessionStorage.getItem(this.basketStorageKey));
      } catch (err) {}
      return baskets;
    } else {
      return null;
    }
  }

  storeBaskets() {
    sessionStorage.setItem(this.basketStorageKey, JSON.stringify(this.baskets));
  }

  removeStoredBaskets() {
    sessionStorage.removeItem(this.basketStorageKey);
  }

  tabChanged(type) {
    // Abort pending call
    if (this.xhr) {
      this.xhr.unsubscribe();
    }
    this.applicationService.showLoader(true);
    this.type = type;
    this.getBaskets();
  }

  basketDeleted(basket: Basket) {
    this.getOpenBaskets(true);
  }

  getDeletedBaskets() {
    this.basketService
      .getDeletedBaskets()
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) this.loaded = true;
        this.applicationService.hideLoader();
        this.baskets = apiResponse.result;
        this.openBaskets = null;
      });
  }
}
