<div
  [ngClass]="{
    invalid: invalid,
    locked: yearAgreementPAndAService.agreementModel?.year_agreement_base.locked || loading
  }"
  *ngIf="!refresh"
>
  <app-agreement-selector
    [agreements]="agreements"
    (agreementSelected)="getAgreement($event, true)"
    [selectedAgreement]="selectedAgreement?.id"
    *ngIf="selectedDealer"
    class="d-block mb-2"
  ></app-agreement-selector>
  <app-agreement-alert
    *ngIf="selectedDealer"
    [signed]="selectedAgreement?.year_agreement_base?.locked && selectedAgreement?.year_agreement_base?.signed"
    [sent]="selectedAgreement?.year_agreement_base?.send ? true : false"
    [sendDate]="selectedAgreement?.year_agreement_base?.send"
    [signedDate]="selectedAgreement?.year_agreement_base?.signed"
  ></app-agreement-alert>
  <ngb-alert *ngIf="selectedDealer && selectedAgreement?.year_agreement_base?.send_exception" [type]="'success'" [dismissible]="false">{{"ACCORDED_ON"|translate}} {{ helperService.formatDate(selectedAgreement?.year_agreement_base?.send_exception)}}</ngb-alert>
  <nav
    ngbNav
    #nav="ngbNav"
    [destroyOnHide]="false"
    (navChange)="tabChange($event)"
    [activeId]="selectedTab"
    class="nav-pills"
  >
    <ng-container *ngIf="yearAgreementPAndAService.agreementModel">
      <ng-container ngbNavItem="dealerInformation" *ngIf="selectedDealer">
        <a ngbNavLink *ngIf="!selectedAgreement?.year_agreement_base?.signed">
          <span
            [ngClass]="
              commercialSettingsInvalid || dealerLevelInvalid
                ? 'text-white bg-danger p-1'
                : ''
            "
          >
            {{ "DEALER" | translate }}
          </span>
        </a>
        <ng-template ngbNavContent>
          <div class="row panels" *ngIf="yearAgreementPAndAService.agreementModel">
            <div class="col-4">
              <app-dealer-information

                [selectedDealerInformation]="selectedDealerInformation"
                [selectedDealer]="selectedDealer"
              ></app-dealer-information>
            </div>
            <div class="col-4">
              <app-dealer-commercial-partner-information [levelSelected]="yearAgreementPAndAService.agreementModel?.selected_dealer_level"
                (levelChanged)="checkBrandSelection(); updateAgreement();"
              ></app-dealer-commercial-partner-information>
            </div>
            <div class="col-4">
              <app-dealer-commercial-partner-settings [minimalTurnover]="minimalTurnover"></app-dealer-commercial-partner-settings>
            </div>
          </div>
        </ng-template>
      </ng-container>
      <ng-container ngbNavItem="brands" *ngIf="selectedDealer && hasMargins">
        <a ngbNavLink *ngIf="!selectedAgreement?.year_agreement_base?.signed">
          <span [ngClass]="brandsInvalid ? 'text-white bg-danger p-1' : ''">
            {{ "BRANDS" | translate }}
          </span>
        </a>
        <ng-template ngbNavContent>
          <div class="mb-4">
            <app-dealer-parts-brands-selection
              #brands
              [brands]="yearAgreementPAndAService.agreementModel.brands ?? yearAgreementPAndAService.agreementModel.brands_new"
              [showToggle]="yearAgreementPAndAService.agreementModel.brands_new ? false : true"
              [exactSelectionRequired]="true"
              label2="{{ 'BRAND' | translate }}"
              label3="{{ ('DISCOUNT' | translate) + ' %' }}"
              [showDiscount]="true"
              *ngIf="yearAgreementPAndAService.agreementModel.year_agreement_base.id"
              [hasPlanning]="yearAgreementPAndAService.agreementModel.year < 2024"
              [hasMaxBrandSelection]="false"
              [rows]="2"
            ></app-dealer-parts-brands-selection>
          </div>
          <app-year-agreement-dealer-ships
            #dealerShips
            *ngIf="yearAgreementPAndAService.agreementModel.year_agreement_base.id"
          ></app-year-agreement-dealer-ships>
        </ng-template>
      </ng-container>
      <ng-container ngbNavItem="exceptions" *ngIf="selectedDealer">
        <a ngbNavLink *ngIf="!selectedAgreement?.year_agreement_base?.signed">{{ "EXCEPTIONS" | translate }}</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-md-12 bg-transparent">
              <div class="card-body section">
                <h4>{{ "EXCEPTIONS" | translate }}</h4>
                <textarea
                  class="form-control"
                  rows="6"
                  [(ngModel)]="
                    yearAgreementPAndAService.agreementModel.exceptions
                  "
                  *ngIf="yearAgreementPAndAService.agreementModel.year_agreement_base.id"
                ></textarea>
                <div *ngIf="yearAgreementPAndAService.agreementModel.realisation_bonuses?.length">
                  <label class="mt-3">{{"JUNCKER_REALISATION_BONUS"|translate}}</label>
                  <select class="form-control agreements" [(ngModel)]="yearAgreementPAndAService.agreementModel.selected_realisation_bonus">
                    <option [value]="''"></option>
                    <option *ngFor="let bonus of yearAgreementPAndAService.agreementModel.realisation_bonuses" [value]="bonus.id">{{bonus.description}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
      <ng-container ngbNavItem="summary">
        <a ngbNavLink *ngIf="selectedDealer">{{
          "YEARGREEMENT_SUMMARY" | translate
        }}</a>
        <ng-template ngbNavContent>
          <ng-container
            *ngIf="
              yearAgreementPAndAService.agreementModel &&
              yearAgreementPAndAService.agreementModel.year_agreement_base.id
            "
          >
            <app-year-agreement-summary
              #summary
              [yearAgreementModel]="yearAgreementPAndAService.agreementModel"
              [id]="yearAgreementPAndAService.agreementModel.year_agreement_base.id"
              (saved)="save()"
              [ts]="ts"
              (send)="agreementSubmitted()"
              [type]="'parts'"
            >
              <textarea
                class="form-control mb-2 bg-light"
                rows="5"
                [(ngModel)]="yearAgreementPAndAService.agreementModel.remarks"
                [disabled]="
                  yearAgreementPAndAService.agreementModel.year_agreement_base.locked
                "
              ></textarea>
              <button
                class="btn btn-dark btn-lg mt-3 ms-2 me-1"
                send
                *ngIf="
                  selectedTab === 'summary' &&
                  !yearAgreementPAndAService.agreementModel.year_agreement_base.locked && (yearAgreementPAndAService.agreementModel.exceptions || yearAgreementPAndAService.agreementModel.selected_realisation_bonus) && selectedDealer && yearAgreementPAndAService.agreementModel.year > 2023
                "
                (click)="sendException()"
                [disabled]="loading ? true : undefined"
                style="min-width: 120px"
              >
                <ng-container *ngIf="!loading">{{
                  "AGREE_EXCEPTION" | translate
                }}</ng-container>
                <span
                  class="spinner-border spinner-border-sm text-white ms-auto"
                  role="status"
                  aria-hidden="true"
                  *ngIf="loading"
                ></span>
              </button>
              <button
                class="btn btn-primary btn-lg mt-3 ms-2 me-1"
                send
                *ngIf="
                  selectedTab === 'summary' &&
                  !yearAgreementPAndAService.agreementModel.year_agreement_base.locked && selectedDealer && (checkUndefined(!yearAgreementPAndAService.agreementModel.year_agreement_base.can_send_exceptions) || yearAgreementPAndAService.agreementModel.year_agreement_base.can_send_exceptions || !yearAgreementPAndAService.agreementModel.exceptions)
                "
                (click)="send()"
                [disabled]="loading ? true : undefined"
                style="min-width: 120px"
              >
                <ng-container *ngIf="!loading">{{
                  "SEND_TO_DEALER" | translate
                }}</ng-container>
                <span
                  class="spinner-border spinner-border-sm text-white ms-auto"
                  role="status"
                  aria-hidden="true"
                  *ngIf="loading"
                ></span>
              </button>
            </app-year-agreement-summary>
          </ng-container>
        </ng-template>
      </ng-container>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
  <div class="col-12 d-flex px-0 pt-2 justify-content-end">
    <button
      *ngIf="
        selectedDealer &&
        yearAgreementPAndAService.agreementModel?.year_agreement_base.can_be_reopened
      "
      class="btn btn-info btn-lg  mt-3"
      (click)="reopenAgreement()"
    >
      {{ "RE_OPEN" | translate }}
    </button>

    <ng-container
      *ngIf="
        yearAgreementPAndAService.agreementModel &&
        !yearAgreementPAndAService.agreementModel.year_agreement_base.locked &&
        yearAgreementPAndAService.agreementModel.year_agreement_base.id &&
        selectedDealer
      "
    >
      <button
        class="btn btn-primary btn-lg ms-auto"
        (click)="save()"
        *ngIf="selectedTab !== 'summary'"
        [disabled]="loading ? true : undefined"
        style="min-width: 120px"
      >
        <ng-container *ngIf="!loading">{{ "SAVE" | translate }}</ng-container>
        <span
          class="spinner-border spinner-border-sm text-white ms-auto"
          role="status"
          aria-hidden="true"
          *ngIf="loading"
        ></span>
      </button>
    </ng-container>
  </div>
   <ng-container *ngIf="!selectedDealer">
    <app-dealer-year-agreements-overview
      [type]="'ANL_Parts'"
      [agreements]="agreements"
    ></app-dealer-year-agreements-overview>
  </ng-container>
</div>
