import { Component, EventEmitter, OnInit, Output, SimpleChanges } from "@angular/core";
import { ApiResponse } from "src/app/interfaces/api-response";
import { BikeDealerShip } from "src/app/interfaces/year-agreement-p-and-a";
import { DealerLevel } from "src/app/interfaces/yearAgreement/dealer-level";
import {
  Brand,
  CommercialAgreements,
} from "src/app/interfaces/yearAgreement/year-agreement-parts";
import { ApplicationService } from "src/app/services/application.service";
import { YearAgreementService } from "src/app/services/year-agreement.service";
import { YearAgreementPAndAService } from "src/app/services/year-agreements/year-agreement/year-agreement-p-and-a.service";

@Component({
  selector: "app-dealer-commercial-partner-information",
  templateUrl: "./dealer-commercial-partner-information.component.html",
  styleUrls: ["./dealer-commercial-partner-information.component.scss"],
})
export class DealerCommercialPartnerInformationComponent implements OnInit {
  @Output() levelChanged: EventEmitter<any> = new EventEmitter<any>();
  levelSelected: string;
  dealerChangeEvent: any;
  dealerShipOptions = Array<BikeDealerShip>()
  retailOptions = Array<BikeDealerShip>()

  constructor(public yearAgreementPAndAService: YearAgreementPAndAService) {}

  ngOnInit() {
    this.dealerShipOptions = typeof this.yearAgreementPAndAService.agreementModel?.bike_dealer_ships !== "undefined" ? [...this.yearAgreementPAndAService.agreementModel?.bike_dealer_ships] as BikeDealerShip[] : []
    this.retailOptions = typeof this.yearAgreementPAndAService.agreementModel?.retail_formulas !== "undefined" ? [...this.yearAgreementPAndAService.agreementModel?.retail_formulas] as BikeDealerShip[] : []
    this.setDealerSettings();
  }

  setDealerSettings(event?) {
    if (typeof event !== "undefined") {
      this.yearAgreementPAndAService.agreementModel.selected_dealer_level = Number(event.target.value);
    }
    this.yearAgreementPAndAService.setDealerSettings();
  }

}
