import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthenticateService } from "src/app/services/authenticate.service";
import { ApplicationService } from "src/app/services/application.service";
import { Router } from "@angular/router";
import { BasketService } from "src/app/services/basket.service";
import * as _ from "underscore";
import { TranslateService } from "@ngx-translate/core";
import { SearchService } from "src/app/services/search.service";
import { Location } from "@angular/common";
import { ApiResponse } from "src/app/interfaces/api-response";
import { MenuLayout } from "src/app/interfaces/menu-layout";
import { MenuItem } from "src/app/interfaces/menuItem";
import { trigger, transition, style, animate, query, stagger } from "@angular/animations";
import { QuickLinksComponent } from "../../cms/quick-links/quick-links/quick-links.component";
import { HelpdeskService } from "src/app/services/helpdesk.service";
import { User } from "src/app/interfaces/user";
import { CompanyGroup } from "src/app/interfaces/company-group";
import { DeviceDetectorService } from "ngx-device-detector";
import { AccountService } from "src/app/services/account.service";
import { LinkedAccount } from "src/app/interfaces/linked-account";
import { forkJoin, fromEvent, timer } from "rxjs";
import { CmsService } from "src/app/services/cms.service";
import { CompanyPromotion } from "src/app/interfaces/company-promotion";
import { TrackingService } from "src/app/services/tracking.service";
import { HelperService } from "src/app/services/helper.service";
import { Basket } from "src/app/interfaces/basket";
import { NgbModal, NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { ChatService } from "src/app/services/chat.service";
import { TokenContainer } from "src/app/interfaces/tokencontainer";
import { SelectLinkedDealerComponent } from "../../login-components/select-linked-dealer/select-linked-dealer.component";
import { AccountSettings } from "src/app/interfaces/account-settings";
import { environment } from "src/environments/environment";
import { SearchMenuComponent } from "../search-menu/search-menu.component";
import { AlertService } from "src/app/services/alert.service";
import { StoryblokService } from "src/app/services/storyblok.service";
import * as moment from "moment";

@Component({
  selector: "app-mega-menu",
  templateUrl: "./mega-menu.component.html",
  styleUrls: ["./mega-menu.component.scss"],
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [
        style({
          transform:
            window.innerWidth < 700 ? "translateX(100%)" : "translateX(0)",
          opacity: 0,
          overflow: "hidden",
        }),
        animate(
          "425ms ease-out",
          style({ transform: "translateX(0)", opacity: 1 })
        ),
      ]),
    ]),
    trigger("pageAnimations", [
      transition(":enter", [
        query(".ani-item", [
          style({ opacity: 0, transform: "translateY(50px)" }),
          stagger(30, [
            animate(
              "400ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({ opacity: 1, transform: "none" })
            ),
          ]),
        ], { optional: true }),
      ]),
    ]),
  ],
})
export class MegaMenuComponent implements OnInit {
  @ViewChild(NgbPopover)
  popoverPreorder: NgbPopover;
  menuActive = false;
  menuHovered = false;
  menuItems: MenuLayout;
  browseGroups: MenuItem[];
  generalItems: MenuItem[];
  settingsItems: MenuItem[];
  selectedMenuItems: MenuItem[];
  selectedSubMenuItems: MenuItem[];
  selectedArticleGroups: MenuItem[];
  selectedArticleSubGroups: MenuItem[];
  @ViewChild(QuickLinksComponent) quickLinks: QuickLinksComponent;
  availableCompanyGroups: any = [];
  username = "";
  initialCompanyGroupCode = "";
  companyGroupCode = "";
  currentLeftItem = "";
  currentCenterItem = "";
  currentRightItem = "";
  profileImageUrl = "";
  showCatalog: any = {};
  companyGroupsSettings: CompanyGroup;
  catalogHoverTimeout: any;
  accounts: LinkedAccount[] = [];
  selectedAccount: string = "";
  showroomMode: any;
  subscriptions: any[] = [];
  disabledForShowroomMode: any = [
    "invoices",
    "dealerinfo",
    "yearAgreement",
    "yearAgreementANL",
    "yearAgreementDACH",
    "yearAgreementDK"
  ];
  hasPreorder: boolean;
  hasEWBP: boolean = false;
  currentLowestItem: string;
  helpPageId = {
    COM: 960422084
  };
  promotionPageId = {
    COM: "ofertas"
  };
  promotions: CompanyPromotion[][] = [];
  promotionsLoaded: boolean;
  preorderBasket: Basket;
  showPreorderPopover: boolean = true;
  preorderBasketParts: any;
  loading: boolean;
  showPromotionsDropdown: boolean;
  lastCheck: any;
  testC: boolean;
  showDealerItems = true;
  showPromotions = true;
  mobile: boolean;
  promotionPageUrl = {
    "ANL": "CMN_PROM",
    "RAL": "CMN_PROM",
    "COM": "CMN_PROM",
    "LAP": "CMN_PROM",
    "VAR": "CMN_PROM",
    "WG": "CMN_PROM",
    "GHO": "&HOMEPROMO=ACCOF2&COMPANY=25",
    "TUN": "CMN_PROM",
  }
  isAdmin: boolean;


  constructor(
    private authenticationService: AuthenticateService,
    private router: Router,
    private basketService: BasketService,
    public applicationService: ApplicationService,
    private searchService: SearchService,
    private location: Location,
    private helpdeskService: HelpdeskService,
    public deviceDetector: DeviceDetectorService,
    private accountService: AccountService,
    private cmsService: CmsService,
    private trackingService: TrackingService,
    private translationService: TranslateService,
    private helperService: HelperService,
    private chatService: ChatService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private storyblokService: StoryblokService
  ) {}

  ngOnInit() {
    this.init();
    this.mobile = this.deviceDetector.isMobile();
    this.subscriptions.push(
      this.applicationService.changeLanguageEmitted$.subscribe((response) => {
        this.init();
      })
    );

    this.getShowroomMode();

    this.subscriptions.push(
      this.applicationService.changeEmitted$.subscribe((response) => {
        this.getShowroomMode();
      })
    );
    this.triggerSearch();
  }

  triggerSearch() {
    const keydown = fromEvent(document.body, "keydown").subscribe((e) => {
      if (e["key"] === "/") {
        const activeElement = document.activeElement;
        if (
          activeElement.tagName.toLowerCase() !== "input" &&
          activeElement.tagName.toLowerCase() !== "textarea"
        ) {
          this.openSearch();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.removeSubscriptions();
  }

  removeSubscriptions() {
    this.subscriptions.forEach((subscription) => {
      if (subscription && Object.keys(subscription)) {
        subscription.unsubscribe();
      }
    });
    document.removeEventListener("visibilitychange", () => {
      this.checkTabFocused();
    });
  }

  getShowroomMode() {
    this.showroomMode = this.applicationService.getShowroomMode(
      this.showroomMode
    );
  }

  showOption(id: string) {
    return (
      !this.showroomMode ||
      (this.showroomMode && this.disabledForShowroomMode.indexOf(id) < 0)
    );
  }

  init() {
    this.lastCheck = moment();
    this.removeSubscriptions();
    this.showDealerItems = !this.applicationService.isPartnerPortal();
    this.companyGroupCode = this.applicationService.getSelectCompanyGroupCode();
    this.isAdmin = this.authenticationService.isAdmin();
    this.companyGroupsSettings =
      this.applicationService.getSettingsForCompanyGroup(this.companyGroupCode) as CompanyGroup
    this.getMenuItems();

    this.subscriptions.push(
      this.helpdeskService.changeEmitted$.subscribe((user: User) => {
        this.getMenuItems(true);
      })
    );

    this.subscriptions.push(
      this.applicationService.emitChangeCompanyGroupChanged$.subscribe(
        (response) => {
          this.companyGroupCode =
            this.applicationService.getSelectCompanyGroupCode();
          this.getMenuItems(true);
        }
      )
    );
    document.addEventListener("visibilitychange", () => {
      this.checkTabFocused();
    });

    this.testC = document.body.classList.contains("ab-test-c");
  }

  checkTabFocused() {
    if (document.visibilityState === "visible") {
      this.refreshMenuItems();
    }
  }

  refreshMenuItems() {
    if (moment().subtract(2, "hours").isSameOrAfter(this.lastCheck)) {
      this.getMenuItems(true);
      this.lastCheck = moment();
    }
  }

  logout(event) {
    event.preventDefault();
    this.applicationService.clearPreorderSettings();

    // Hide UI
    const mainContainer = document.getElementById("content_container");
    if (mainContainer) {
      mainContainer.style.display = "none";
    }
    this.authenticationService.logout(
      this.storyblokService.isOnPreviewPage() ? true : false
    );
    return false;
  }

  setCompanyGroup(event, code) {
    this.currentLeftItem = "";
    this.currentCenterItem = "";
    this.currentRightItem = "";
    this.applicationService.saveSelectedCompanyGroupCode(code);
    this.companyGroupsSettings =
      this.applicationService.getSettingsForCompanyGroup(code) as CompanyGroup
    this.companyGroupCode = code;
    this.basketService.emitChange("");
    this.location.go(code);
    sessionStorage.removeItem("news-" + this.companyGroupCode);
    this.chatService.toggleChat(code === "ANL"); // Enable chat for ANL only
    this.trackingService.hamburger("Brand", this.companyGroupCode);
    this.router.navigateByUrl(
      this.applicationService.getSelectCompanyGroupCode() + "/"
    );
    if (event) {
      event.preventDefault();
    }
    this.menuActive = false;
    return false;
  }

  // Opens the mega menu
  openMenu() {
    if (!this.menuActive) {
      this.initialCompanyGroupCode =
        this.applicationService.getSelectCompanyGroupCode();
      this.companyGroupCode = this.initialCompanyGroupCode;
      this.availableCompanyGroups = this.applicationService.getSetting(
        "companyGroups",
        "[]"
      );

      // Sets hasEWBP to true if company 102 is present in any of the available companygroups
      if (Array.isArray(this.availableCompanyGroups) && this.availableCompanyGroups.length) {
        this.hasEWBP = this.availableCompanyGroups.some(function(el) {
          return el.companies.includes(102);
        });
      } else {
        // Handle the case where availableCompanyGroups is not an array
        this.hasEWBP = false;
      }

      this.currentLeftItem = "";
      this.currentCenterItem = "";
      this.currentRightItem = "";
    }

    if (!this.menuItems && !this.menuActive) {
      this.getMenuItems();
    }

    this.menuActive = !this.menuActive;
    if (!this.menuActive) {
      this.checkCompanyChange(true);
    }
  }

  dontopenMenuItem(item: MenuItem) {
    item.will_be_active = false;
  }

  openMenuItem(item: MenuItem) {
    item.will_be_active = true;
    if (!this.deviceDetector.isMobile() && !this.deviceDetector.isTablet()) {
      setTimeout(() => {
        if (item.will_be_active) {
          item.active = true;
        }
      }, 100)
    }

    if (item.name === "usersettings" && !this.accounts?.length) {
      this.subscriptions.push(
        this.accountService
          .getLinkedDealers()
          .subscribe((apiResponse: ApiResponse) => {
            if (this.helperService.checkResponse(apiResponse)) {
              this.accounts = apiResponse.result;
            }
          })
      );
    }
  }

  toggleMenuItem(item, event) {
    if (
      (this.deviceDetector.isMobile() || this.deviceDetector.isTablet()) &&
      event.srcElement.classList.contains("topItem")
    ) {
      item.active = !item.active;
    }
  }

  // Closes the menu
  closeMenu(event) {
    const excludeElements = [
      "dropdownManual",
      "typeahead-http",
      "searchForm",
      "menuButton",
      "menuButtonIcon",
      "back1",
      "back2",
    ];
    const elId = event.target.id;
    if (excludeElements.indexOf(elId) < 0 && this.menuActive) {
      this.menuActive = false;
      this.checkCompanyChange(elId !== "submitSearch");
    }
  }

  checkCompanyChange(redirect: boolean) {
    if (
      this.initialCompanyGroupCode !==
      this.applicationService.getSelectCompanyGroupCode()
    ) {
      this.initialCompanyGroupCode =
        this.applicationService.getSelectCompanyGroupCode();

      if (redirect) {
        this.router.navigateByUrl(
          this.applicationService.getSelectCompanyGroupCode() + "/"
        );
      }
    }
  }

  // Get all the menu items
  getMenuItems(clear = false) {
    this.selectedMenuItems = [];
    this.selectedSubMenuItems = [];
    this.selectedArticleGroups = [];
    this.selectedArticleSubGroups = [];

    this.applicationService.getMenuItems(clear).then((data: MenuLayout) => {
      this.menuItems = data;
      this.profileImageUrl = this.menuItems.avatar;
      this.generalItems = this.menuItems.menu_items;
      this.browseGroups = this.menuItems.browse_groups;
      this.settingsItems = this.menuItems.settings;
      this.menuItems.settings[0].description = this.menuItems.user_name;
      this.generalItems.push(this.menuItems.settings[0]);
      this.username = this.menuItems.user_name;

      // Make subitems alphabetical
      if (this.browseGroups && this.browseGroups.length) {
        for (let articleGroup of this.browseGroups) {
          if (articleGroup.sub_items && articleGroup.sub_items.length) {
            articleGroup.sub_items = _.sortBy(
              articleGroup.sub_items,
              function (i) {
                if (i.description && i.description.length) {
                  return i.description.toLowerCase();
                }
              }
            );
            // Make subsubitems also alphabetical
            for (let subArticleGroup of articleGroup.sub_items) {
              if (
                subArticleGroup.sub_items &&
                subArticleGroup.sub_items.length
              ) {
                subArticleGroup.sub_items = _.sortBy(
                  subArticleGroup.sub_items,
                  function (i) {
                    if (i.description && i.description.length) {
                      return i.description.toLowerCase();
                    }
                  }
                );
              }
            }
          }
        }
      }

      // Hide imagebank when companygroup is WBP
      if (
        this.companyGroupCode === "WG" &&
        this.applicationService.checkIfDealerHasCompany(101 && 102)
      ) {
        for (let item of this.menuItems.menu_items) {
          if (item.name === "service" && item.sub_items) {
            for (let i = 0; i < item.sub_items.length; i++) {
              let items = item.sub_items[i];
              if (items.name == "imagebank") {
                item.sub_items.splice(i, 1);
              }
            }
          }
        }
      }

      // Hide ebsc when language is NL
      if (
        this.companyGroupCode === "ANL" &&
        this.applicationService.getSelectedLanguage() === "NL"
      ) {
        for (let item of this.menuItems.menu_items) {
          if (item.name === "service" && item.sub_items) {
            for (let i = 0; i < item.sub_items.length; i++) {
              let menuItem = item.sub_items[i];
              if (menuItem.name === "ebsc") {
                menuItem.sub_items[0].class = "d-none";
              }
            }
          }
        }
      }

      // Check if user has preorder option
      const orderItems = _.find(data.menu_items, { name: "order" });
      if (orderItems) {
        const hasPreorder = _.find(orderItems.sub_items, { name: "preorder" });
        this.hasPreorder = hasPreorder ? true : false;

        if (hasPreorder) {
          this.getPreorderBasket();
        }
      } else {
        this.hasPreorder = false;
      }
    });
  }

  // When selecting an menu item
  selectArticleGroup(event, item: MenuItem) {
    this.currentCenterItem = "";
    if (item.sub_items) {
      this.selectedArticleGroups = Object.values(item.sub_items);
      this.selectedArticleSubGroups = [];
      this.selectedMenuItems = [];
      this.selectedSubMenuItems = [];
      this.currentCenterItem = "";
    } else {
      this.gotoSearch();
    }
    event.preventDefault();
    return false;
  }

  viewAllFromArticleGroup(event) {
    event.preventDefault();
    this.gotoSearch();
    return false;
  }

  selectProductGroup(event, item: MenuItem, select) {
    this.currentRightItem = "";
    if (item.sub_items) {
      this.selectedArticleSubGroups = this.selectItem(item, event);
    } else {
      this.gotoSearch();
    }
    event.preventDefault();
    return false;
  }

  selectSubGroup(event, item: MenuItem) {
    this.currentRightItem = item.name;
    this.gotoSearch();
    this.searchService.setGroups("");
    this.menuActive = false;
    event.preventDefault();
    return false;
  }

  groupSelectedFromNavbar(groupData) {
    this.currentLeftItem = groupData['productGroup'];
    this.currentCenterItem = groupData['articleGroup'] ?? '';
    this.currentRightItem = groupData['articleSubGroup'] ?? '';
    this.currentLowestItem = groupData['articleSubSubGroup'] ?? '';
    this.showCatalog["active"] = false;
    this.gotoSearch();
  }

  selectGroup(
    event,
    productGroup,
    articleGroup = "",
    articleSubGroup = "",
    articleSubSubGroup = ""
  ) {
    this.currentLeftItem = productGroup;
    this.currentCenterItem = articleGroup;
    this.currentRightItem = articleSubGroup;
    this.currentLowestItem = articleSubSubGroup;
    this.showCatalog["active"] = false;
    this.gotoSearch();
    if (typeof event !== "undefined") {
      event.preventDefault();
    }
    return false;
  }

  back() {
    event.preventDefault();
    return false;
  }

  // Go to search page
  gotoSearch() {
    let url =
      this.companyGroupCode +
      "/search#filter/COMMON=&groups=" +
      this.currentLeftItem +
      (this.currentCenterItem ? "," + this.currentCenterItem : "") +
      (this.currentRightItem ? "," + this.currentRightItem : "") +
      (this.currentLowestItem ? "," + this.currentLowestItem : "");

    const leftItem = this.browseGroups.find((b: MenuItem) => {
      return b.name == this.currentLeftItem;
    }) as MenuItem;

    if (leftItem) {
      url += `&categories=${this.helperService.urlEncode(
        leftItem.description
      )}`;

      if (this.currentCenterItem && typeof leftItem.sub_items !== "undefined") {
        const centerItem = leftItem.sub_items.find((b: MenuItem) => {
          return b.name == this.currentCenterItem;
        }) as MenuItem;

        if (centerItem) {
          url += `,${this.helperService.urlEncode(centerItem.description)}`;

          if (this.currentRightItem) {
            const rightItem = centerItem.sub_items.find((b: MenuItem) => {
              return b.name == this.currentRightItem;
            }) as MenuItem;
            url += `,${this.helperService.urlEncode(rightItem.description)}`;

            if (this.currentLowestItem) {
              const lowestItem = rightItem.sub_items.find((b: MenuItem) => {
                return b.name == this.currentLowestItem;
              }) as MenuItem;
              url += `,${this.helperService.urlEncode(lowestItem.description)}`;
            }
          }
        }
      }
    }

    if (this.router.url.indexOf("/search") >= 0) {
      this.location.go(url);
    } else {
      this.router.navigateByUrl(url);
    }

    this.searchService.setGroups("");
    this.menuActive = false;

    this.trackingService.hamburger(
      "Catalogus",
      this.currentLeftItem,
      this.currentCenterItem,
      this.currentRightItem,
      this.currentLowestItem
    );
  }

  selectMenuItem(event, item: MenuItem) {
    this.selectedArticleGroups = [];
    this.selectedArticleSubGroups = [];
    this.selectedMenuItems = this.selectItem(item, event);
    this.selectedSubMenuItems = [];
    event.preventDefault();
    return false;
  }

  selectSubMenuItem(event, item: MenuItem) {
    this.selectedSubMenuItems = this.selectItem(item, event);
    return false;
  }

  selectSubSubMenuItem(event, item: MenuItem) {
    this.selectMenuItem(event, item);
    return false;
  }

  selectItem(item: MenuItem, event) {
    event.preventDefault();

    const items = typeof item.sub_items !== "undefined" ? item.sub_items : [];

    if (items.length) {
      return items;
    } else {
      window.location.href = item.url;
    }

    return [];
  }

  showCatalogHover() {
    if (!this.deviceDetector.isMobile() && !this.deviceDetector.isTablet()) {
      this.catalogHoverTimeout = setTimeout(() => {
        this.showCatalog["active"] = true;
      }, 100);
    }
  }

  hideCatalogHover() {
    clearTimeout(this.catalogHoverTimeout);
    this.showCatalog["active"] = false;
  }

  showSettings($event) {}

  gotoSearchPage(filter: string, event: any) {
    this.showCatalog["active"] = false;
    this.router.navigateByUrl(
      `${this.companyGroupCode}/search#filter/COMMON=${filter}`
    );
    setTimeout(() => {
      this.searchService.setGroups("");
    }, 0);

    this.menuActive = false;
    event.preventDefault();
    return false;
  }

  gotoItem(item: MenuItem) {
    let url = item.url;
    let internal = false;

    // Check if it's download
    if (url.indexOf("downloadpdf") >= 0) {
      this.cmsService.getDownload(url);
      return false;
    }

    // sign in to the Crm service portal
    if (item.name === "servicePortal") {
      if (!this.loading) {
        this.loading = true;
        this.accountService
          .portalSignin()
          .subscribe((apiResponse: ApiResponse) => {
            this.loading = false;
            if (this.helperService.checkResponse(apiResponse)) {
              window.location.href = apiResponse.result;
            } else {
              this.alertService.showPopup(
                apiResponse
                  ? apiResponse.result
                  : this.translationService.instant("ERROR"),
                "error",
                0
              );
            }
          });
      }
      return false;
    }

    if (item.name === "nda") {
      this.accountService.checkNda();
      return false;
    }

    if (item.name === "conditions") {
      this.accountService.checkGeneralConditions();
      return false;
    }

    if (url === "#") {
      return false;
    }

    if (item.url.indexOf("/[CompanyGroup]") >= 0) {
      internal = true;
      url = item.url.replace("/[CompanyGroup]", this.companyGroupCode);
    } else if (
      (item.url.indexOf(this.companyGroupCode) >= 0 ||
        item.url
          .toUpperCase()
          .indexOf(this.applicationService.getSelectCompanyGroupCode()) >= 0 ||
        item.url.indexOf("Logout") >= 0 ||
        item.url.indexOf("helpdesk") >= 0 ||
        item.url.indexOf("financial") >= 0 ||
        item.url.indexOf("Settings") >= 0) &&
        typeof item.target === "undefined"
    ) {
      internal = true;
    }
    this.menuActive = false;

    // Check if external login siten
    const key = this.authenticationService.getExternalKey(item.url);
    if (key) {
      this.subscriptions.push(
        this.authenticationService
          .loginExternalSite(key)
          .subscribe((apiResponse: ApiResponse) => {
            if (apiResponse && apiResponse.success) {
              window.open(apiResponse.result, "_new");
            }
          })
      );
    } else {
      if (internal) {
        // Do a hard reload to get the new App version
        if (this.applicationService.newVersionAvailable) {
          window.location.href = url;
        } else {
          this.router.navigateByUrl(url);
          if (url.includes("/search#filter")) {
            this.searchService.setGroups("");
          }
        }
      } else {
        if (url.indexOf("http") < 0) {
          url = `https://www.accentry.com/${url}`;
        }
        window.open(url, "_new");
      }
    }

    return false;
  }

  gotoPreorder() {
    this.showPreorderPopover = false;
    const url = `${this.companyGroupCode}/search#filter/&pageSize=24&preorder=1`;
    if (window.location.href.indexOf("search") >= 0) {
      window.location.href = url;
    } else {
      this.router.navigateByUrl(url);
    }
  }

  loadPromotions() {
    this.hidePreorderPopover(false);
    this.getPromotions();
  }

  getPromotions() {
    this.promotionsLoaded = false;
    this.searchService.getPromotions().then((apiResponse: ApiResponse) => {
      this.promotionsLoaded = true;

      if (this.helperService.checkResponse(apiResponse)) {
        this.promotions = Object.values(apiResponse.result);
      }
    });
  }

  // Get the description for the menu item with custom description for a company group
  getDescription(item: MenuItem) {
    let description = item.description;

    return description;
  }

  hidePreorderPopover(delay = true) {
    setTimeout(() => {
      if (typeof this.popoverPreorder !== "undefined") {
        this.popoverPreorder.close();
      }
    }, delay ? 3000 : 0)
  }

  getPreorderBasket() {
    setTimeout(() => {
      this.showPreorderPopover = true;
      if (this.popoverPreorder) {
        this.basketService
          .getPreorderBasket("Bicycles")
          .subscribe((apiResponse: ApiResponse) => {
            if (this.helperService.checkResponse(apiResponse)) {
              this.preorderBasket = apiResponse.result;
              if (typeof this.popoverPreorder !== "undefined") {
                this.popoverPreorder.open();
              }

              this.hidePreorderPopover(true);
            } else {
              this.preorderBasket = null;
            }
          });

        this.basketService
          .getPreorderBasket("Parts")
          .subscribe((apiResponse: ApiResponse) => {
            if (this.helperService.checkResponse(apiResponse)) {
              this.preorderBasketParts = apiResponse.result;
              if (this.preorderBasketParts && typeof this.popoverPreorder !== "undefined") {
                this.popoverPreorder.open();
              }
            } else {
              this.preorderBasketParts = null;
            }
          });
      }
    });
  }

  selectLinkedAccountModal() {
    this.accountService
      .getLinkedDealers()
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          const modalRef = this.modalService.open(SelectLinkedDealerComponent, {
            centered: true,
            container: "#modalContainer",
          });
          modalRef.componentInstance.dealers = apiResponse.result;
          modalRef.componentInstance.dealerSelected.subscribe(
            (tokenContainer: TokenContainer) => {
              this.authenticationService.saveTokens(tokenContainer);

              // Get user information
              this.accountService
                .getAccountSettings()
                .subscribe((response: ApiResponse) => {
                  if (response && response.result) {
                    const accountSettings: AccountSettings = response.result;
                    this.accountService.saveAccountSettings(accountSettings);

                    forkJoin(
                      this.accountService.getAvailableCompanyGroups(),
                      this.accountService.getAccountInformation()
                    ).subscribe((apiResponse: any[]) => {
                      if (apiResponse[0].success && apiResponse[1]) {
                        this.applicationService.saveCompanyGroups(
                          apiResponse[0].result
                        );

                        const accountInformation = apiResponse[1].result;

                        // Save settings in global var
                        this.applicationService.saveAccountInformation(
                          accountInformation
                        );

                        if (
                          typeof accountSettings.company_group === "undefined"
                        ) {
                          const companyGroups =
                            this.applicationService.getAvailableCompanyGroups();
                          accountSettings.company_group = companyGroups[0];
                        }

                        // Delete menu items
                        this.applicationService.deleteStoredItems(
                          `${this.companyGroupCode}menuItems`
                        );

                        this.setCompanyGroup(
                          null,
                          accountSettings.company_group
                        );
                        modalRef.close();

                        // Redirect to Okta
                        if (tokenContainer.session_token) {
                          let oktaReturnUrl = `${window.location.origin}/${this.companyGroupCode}`;

                          this.authenticationService.redirectToOkta(
                            tokenContainer,
                            oktaReturnUrl
                          );
                        }
                      }
                    });
                  }
                });
            }
          );
        }
      });
  }

  openSearch() {
    const modal = document.getElementById("search-menu-modal");
    if (!modal) {
      const modalRef = this.modalService.open(SearchMenuComponent, {
        centered: true,
        windowClass: "searchModal",
        container: "#modalContainer",
        backdrop: "static",
      });

      modalRef.componentInstance.itemClicked.subscribe((item: MenuItem) => {
        this.gotoItem(item);
        modalRef.close();
      });

      modalRef.componentInstance.groupClicked.subscribe((group: string[]) => {
        this.selectGroup(undefined, group[0], group[1], group[2], group[3]);
        modalRef.close();
      });
    }
  }
}
