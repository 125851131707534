<div class="row">
  <div class="col-md-12 card border-0">
    <div class="card-body">
      <h4>{{'DEALER_COMMERCIAL_AGREEMENTS'|translate}}</h4>
      <div class="row mt-3">
        <div class="mb-3 col-md-12" *ngIf="yearAgreementPAndAService.agreementModel?.bike_dealer_ships">
          <label for="dealerShip" class="d-block"><b>{{"BIKE_DEALER_SHIP"|translate}}</b></label>
          <select id="dealerShip" class="form-control" name="BIKE_DEALER_SHIP" [ngClass]="{'no-value': !yearAgreementPAndAService.agreementModel.selected_bike_dealer_ship}" [ngModelOptions]="{ standalone: true }" required [(ngModel)]="yearAgreementPAndAService.agreementModel.selected_bike_dealer_ship" (change)="setDealerSettings()">
            <option selected [value]="''">{{"MAKE_A_CHOICE"|translate}}...</option>
            <option *ngFor="let bike_dealer_ship of dealerShipOptions" [value]="bike_dealer_ship.id">{{bike_dealer_ship.description}}</option>
          </select>
        </div>
        <div class="mb-3 col-md-12 mt-3" *ngIf="yearAgreementPAndAService.agreementModel?.retail_formulas">
          <label for="RETAIL_FORMULA" class="d-block"><b>{{"RETAIL_FORMULA"|translate}}</b></label>
          <select id="RETAIL_FORMULA" class="form-control" name="RETAIL_FORMULA" [ngClass]="{'no-value': !yearAgreementPAndAService.agreementModel.selected_retail_formula}" [ngModelOptions]="{ standalone: true }" required [(ngModel)]="yearAgreementPAndAService.agreementModel.selected_retail_formula" (change)="setDealerSettings(); ">
            <option selected [value]="''">{{"MAKE_A_CHOICE"|translate}}...</option>
            <ng-container *ngFor="let retail_formula of retailOptions" >
              <option [value]="retail_formula.id">{{retail_formula.description}}</option>
            </ng-container>
          </select>
        </div>
        <div class="mb-3 col-md-12 mt-3" *ngIf="yearAgreementPAndAService.agreementModel?.dealer_levels">
          <label for="DEALER_CATEGORIE"><b>{{"DEALER_CATEGORIE"|translate}}</b></label>
          <select id="DEALER_CATEGORIE" class="form-control" name="level" [ngClass]="{'no-value': !yearAgreementPAndAService.agreementModel.selected_dealer_level}"  required (change)="setDealerSettings($event); levelChanged.emit(true)">
            <option [selected]="" [value]="''">{{"MAKE_A_CHOICE"|translate}}...</option>
            <option *ngFor="let level of yearAgreementPAndAService.agreementModel.dealer_levels" [value]="level.id" [selected]="level.id == yearAgreementPAndAService.agreementModel.selected_dealer_level">
              {{level.description}}
              <ng-container *ngIf="level.brands && level.brands < 99"> ({{level.brands}} {{"BRANDS"|translate}})</ng-container></option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>
