import { Article } from "./article";

export interface BasketAction {
  type: string;
  basketId: number|string;
  article: Article;
  articles: Article[];
  basketArticleId: number;
  value: number;
  quantity: number;
}

export class BasketAction implements BasketAction {
}
