import { Component, OnInit, Input } from "@angular/core";
import { OrderLine } from "src/app/interfaces/financial/openorders/order-line";
import { EditOpenOrderComponent } from "../edit-open-order/edit-open-order.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ShippingAddressGroup } from "src/app/interfaces/shipping-address-group";
import { FinancialService } from "src/app/services/financial.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { AlertService } from "src/app/services/alert.service";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationService } from "src/app/services/application.service";
import * as moment from "moment";
import { saveAs } from "file-saver";

@Component({
  selector: "app-open-order-line",
  templateUrl: "./open-order-line.component.html",
  styleUrls: ["./open-order-line.component.scss"]
})
export class OpenOrderLineComponent implements OnInit {
  @Input() orderLine: OrderLine;
  @Input() shippingAddressGroup: ShippingAddressGroup;
  showDetails: boolean;
  @Input() image: string;

  get showDeliveryWeek() {
    const maxDate = "20241231";
    if (this.applicationService.getSelectCompanyGroupCode() === 'WG') {
      return moment(
        this.orderLine.delivery_week_or_date,
        "DD-MM-YYYY"
      ).isBefore(moment(maxDate));
    } else {
      const weekData = this.orderLine.delivery_week_or_date.split(" ");
      return moment()
        .day("Monday")
        .year(Number(weekData[1]))
        .isoWeek(Number(weekData[0]))
        .isBefore(moment(maxDate));
    }
  }

  constructor(
    private modalService: NgbModal,
    private financialService: FinancialService,
    public applicationService: ApplicationService,
    private alertService: AlertService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {}

  inlineEditOrder() {
    const editData = { ...this.orderLine };
    delete editData.delivery_week_or_date;
    delete editData.shipping_id;
    this.financialService
      .editOrder(editData, null)
      .subscribe((response: ApiResponse) => {
        if (response.success) {
          this.alertService.showInfoNotification(response.result);
        } else {
          this.alertService.showDangerNotification(
            this.translateService.instant("ERROR")
          );
        }
        this.financialService.emitChange(response);
      });
  }

  editOrder(openOrder: OrderLine) {
    const modalRef = this.modalService.open(EditOpenOrderComponent, {
      centered: true,
      size: openOrder.article ? "lg" : null,
      windowClass: "large",
      container: "#modalContainer",
    });
    modalRef.componentInstance.order = Object.assign({}, openOrder);
    modalRef.componentInstance.originalOrder = openOrder;
    modalRef.componentInstance.shippingAddressGroup = {...this.shippingAddressGroup};
  }

  downloadOpenOrder() {
    // temp fix, when Accentry is updated, 3 lines below can be removed
    if (this.orderLine.erp_id.indexOf("-") === -1) {
      this.orderLine.erp_id = "WS-" + this.orderLine.erp_id;
    }

    this.financialService
      .getOpenOrdersPdfFile([`${this.orderLine.erp_id}`])
      .subscribe((blob: Blob) =>
        saveAs(blob, `openorderlines-${this.orderLine.erp_id}.pdf`)
      );
  }
}
