<div
  *ngIf="article"
  class="h-100"
  [ngClass]="[
    article.keyDown ? 'hoverable' : '',
    article.selected ? 'selected' : '',
    autoShowDetails ? 'show' : ''
  ]"
  (mouseenter)="setHover($event)"
  (mouseleave)="removeHover()"
  [@scale]
  [@.disabled]="!animate"
>

  <span class="removeFromList" *ngIf="specs" (click)="remove()">&times;</span>
  <app-article-image
    class="d-inline-block mt-2 w-100 text-center thumbnail"
    [articleType]="article.article_type"
    [imageUrl]="
      article.thumbs && article.thumbs.length ? article.thumbs[0] : ''
    "
    [useLazyLoad]="useLazyLoad"
    [description]="article.description"
    [productGroup]="article.product_group_codes"
    [width]="300"
    [height]="400"
    (click)="gotoItem($event, article)"
    (imageClick)="gotoItem($event, article)"
    [classList]="''"
  ></app-article-image>
  <div class="card-body px-3 py-0">
    <div class="front">
      <h5 class="card-title" (click)="gotoItem($event, article)">
        <span [innerHTML]="article.description"></span>
        <small
          [innerHTML]="
            (article.caption ? article.caption : '') +
            ' ' +
            (article.single_article_code ? article.single_article_code : '')
          "
        ></small>
        <small class="small" *ngIf="article.season && (!['RAL','ANL'].includes(selectedCompanyGroup) || showModelYear)">{{ article.season }}</small>
        <app-highlight-badge
          [article]="article"
          *ngIf="
            article.stock_status < 5 ||
            article.setitem ||
            article.new ||
            article.is_lease
          "
          class="absolute"
        >
        </app-highlight-badge>
      </h5>
      <ng-container *ngIf="!hideMatrix">
        <ng-container *ngTemplateOutlet="matrix"></ng-container>
      </ng-container>
    </div>

    <div class="card-text" *ngIf="article.retail_price">

      <h4 *ngIf="article.retail_price">
        <app-retail-price
          [price]="article.retail_price"
          [showLabel]="true"
        ></app-retail-price>
        <app-lease-price
          *ngIf="article.lease_start_price && article.lease_start_price.value && article.is_lease"
          [showTable]="!article.has_flex_batt"
          [lowestPrice]="article.lease_start_price"
          [articleCode]="article.single_article_code"
          [leasePrices]="article.lease_price"
        ></app-lease-price>
      </h4>
      <h5 [style.display]="article.gross_price ? '' : 'none'">
        <app-gross-price
          [price]="article.gross_price"
          [showLabel]="true"
        ></app-gross-price>
      </h5>
      <h4
        *ngIf="!article.hide_nett_price"
      >
        <app-nett-price
          [price]="article.net_price"
          [hasDifferentPrices]="article.has_different_prices"
          [showLabel]="true"
          [volumePrices]="article.volume_price"
          [article]="article"
        >
        </app-nett-price>
        <div *ngIf="article.volume_price" class="d-block mt-1">
          <ng-template #popContent> <app-volume-prices [headerLeft]="'QUANTITY'" [headerRight]="'PRICE'"
              [popoverTitle]="'VOLUME_PRICES'" [price]="article.volume_price"
              [regularPrice]="article.net_price"></app-volume-prices>
          </ng-template>
          <button type="button" class="btn btn-sm btn-outline-secondary d-block" [ngbPopover]="popContent"
            [popoverTitle]="popTitle">
            {{"VOLUME_PRICES"|translate}}
          </button>
        </div>
      </h4>
      <app-spec-list [specs]="specs"></app-spec-list>
      <ng-content select="[xl]"></ng-content>
    </div>
    <app-bicycle-lease-details
      *ngIf="article.lease_details"
    ></app-bicycle-lease-details>

    <!-- Overlay -->

    <div
      class="card-details-overlay"
      *ngIf="(article['keyDown'] || autoShowDetails) && !hideOverlay"
      [@inOutAnimation]
    >
      <small class="position-absolute baseCollection ps-1 pt-1">
        <app-focus-model
          *ngIf="article.base_collection"
          [size]="'small'"
        ></app-focus-model>
      </small>
      <app-article-image
        class="d-inline-block mt-2 w-100 text-center thumbnail"
        [articleType]="article.article_type"
        [imageUrl]="
          article.thumbs && article.thumbs.length ? article.thumbs[0] : ''
        "
        [description]="article.description"
        [showControls]="true"
        [images]="article.thumbs"
        (click)="gotoItem($event, article)"
        [classList]="''"
        [useLazyLoad]="false"
      ></app-article-image>
      <i class="material-icons"></i>
      <div class="card-body px-3 py-0">
        <h5 class="card-title" (click)="gotoItem($event, article)">
          <span [innerHTML]="article.description"></span>
          <small
            [innerHTML]="
              (article.caption ? article.caption : '') +
              ' ' +
              (article.single_article_code ? article.single_article_code : '')
            "
          ></small>
          <small class="small" *ngIf="article.season && (!['RAL','ANL'].includes(selectedCompanyGroup) || showModelYear)">{{ article.season }}</small>
          <app-highlight-badge
            (click)="gotoItem($event, article)"
            [article]="article"
            *ngIf="
              article.stock_status < 5 || article.setitem || article.is_lease
            "
            class="absolute"
          ></app-highlight-badge>
        </h5>
        <ng-container *ngTemplateOutlet="matrix"></ng-container>

        <div
          class="card-text"
          *ngIf="article.retail_price"
          (click)="gotoItem($event, article)"
        >
          <h4 *ngIf="article.retail_price">
            <app-retail-price
              [price]="article.retail_price"
              [showLabel]="true"
            ></app-retail-price>
            <app-lease-price
              *ngIf="
                article.lease_start_price && article.lease_start_price.value && article.is_lease
              "
              [showTable]="!article.has_flex_batt"
              [lowestPrice]="article.lease_start_price"
              [articleCode]="article.single_article_code"
              [leasePrices]="article.lease_price"
            ></app-lease-price>
          </h4>
          <h5 [style.display]="article.gross_price ? '' : 'none'">
            <app-gross-price
              [price]="article.gross_price"
              [showLabel]="true"
            ></app-gross-price>
          </h5>
          <h4
            *ngIf="!article.hide_nett_price"
          >
            <app-nett-price
              [price]="article.net_price"
              [hasDifferentPrices]="article.has_different_prices"
              [showLabel]="true"
              [volumePrices]="article.volume_price"
            >
            </app-nett-price>
            <div *ngIf="article.volume_price" class="d-block mt-1">
              <ng-template #popContent> <app-volume-prices [headerLeft]="'QUANTITY'" [headerRight]="'PRICE'"
                  [popoverTitle]="'VOLUME_PRICES'" [price]="article.volume_price"
                  [regularPrice]="article.net_price"></app-volume-prices>
              </ng-template>
              <button type="button" class="btn btn-sm btn-outline-secondary d-block show-volume-prices" [ngbPopover]="popContent"
                [popoverTitle]="popTitle">
                {{"VOLUME_PRICES"|translate}}
              </button>
            </div>

          </h4>

        </div>
        <app-spec-list [specs]="specs"></app-spec-list>
        <app-bicycle-lease-details
          *ngIf="article.lease_details"
        ></app-bicycle-lease-details>
        <ng-content select="[buttons]"></ng-content>
        <button
          type="button"
          class="btn infoBtn clickable withOpacity mb-3 border"
          (click)="openModal(article)"
        >
          {{ "INFORMATION" | translate }}
        </button>
        <input
          type="checkbox"
          class="selectForAdd"
          [hidden]="
            !article.authorized_to_order &&
            !article.authorized_to_place_in_basket
          "
          [(ngModel)]="article.selected"
          (click)="selectArticle($event)"
          *ngIf="article.single_article_code"
        />
        <app-add-to-basket-button *ngIf="showAddButtons" [article]="article">
          <app-add-to-lease-button
            class="d-block ms-1 ms-sm-0"
            [article]="article"
            *ngIf="article.is_lease && article.article_type === 'F'"
            [fromLeaseRequest]="leaseRequest"
            [disabled]="!article.single_article_code"
          ></app-add-to-lease-button>
          <ng-content></ng-content>
        </app-add-to-basket-button>
        <button
          class="btn btn-dark btn-block"
          *ngIf="showBomButton && article.bom"
          (click)="links.showBom($event)"
        >
          {{ "BOM" | translate }}
        </button>
        <app-article-links
          #links
          [article]="article"
          [theme]="'dark'"
          [hidden]="
            !article.authorized_to_order &&
            !article.authorized_to_place_in_basket
          "
        ></app-article-links>
      </div>
    </div>
    <ng-content select="[footer]"></ng-content>
  </div>

  <ng-template #matrix>
    <div class="mb-2">
    <app-matrix
      *ngIf="
        (article.matrix && article.matrix.options.length > 1) ||
        (article.matrix && article.matrix.options.length === 1 && article.article_type==='F' && selectedCompanyGroup === 'ANL') ||
          (article.matrix &&
            article.matrix.options.length === 1 &&
            article.matrix.options[0].value.length > 1);
        else stockStatus
      "
      [article]="article"
      (articleSelected)="articleChanged($event)"
    ></app-matrix>
    <ng-template #stockStatus>
      <app-stock-status
        [showText]="true"
        class="w-100 d-block pt-1"
        *ngIf="!article.matrix || article.matrix.options.length <= 1"
        (click)="gotoItem($event, article)"
        [article]="article"
      ></app-stock-status>
    </ng-template>
    </div>
  </ng-template>
</div>
