<p-accordion (onOpen)="open($event)" (onClose)="close($event)">
  <p-accordionTab header="Settings" [headerStyleClass]="'py-1'">
    <div class="d-flex align-items-center">
      <p-inputSwitch class="mt-1 me-1" inputId="v2basket" (onChange)="saveSettings()"
        [(ngModel)]="v2basket"></p-inputSwitch>
      <label>V2 baskets</label>
    </div>
    <div class="text-start mt-2" *ngIf="isPartnerPortal">
      <label class="d-block">{{"Store"|translate}}</label>
      <select class="form-control d-block" [(ngModel)]="storeName" (change)="setStore()">
        <option value="default">Default</option>
        <option value="raleigh">Raleigh</option>
        <option value="wipro">Wipro</option>
      </select>
    </div>
  </p-accordionTab>
  <p-accordionTab header="Index information" [headerStyleClass]="'py-1'" *ngIf="isAdmin">
    <span *ngIf="indexData === null" class="ms-2 spinner-border spinner-border-sm d-inline-block" role="status" aria-hidden="true"></span>
    <table class="table table-bordered table-striped text-start" *ngIf="indexData !== null && tabIndex === 1">
      <tbody>
        <tr>
          <td>Creating index:</td>
          <td>{{indexData.creating_index}}</td>
        </tr>
        <tr>
          <td>Index name:</td>
          <td>{{indexData.index_name}}</td>
        </tr>
        <tr>
          <td>PIM parser busy:</td>
          <td>{{indexData.pim_parser_busy}}</td>
        </tr>
        <tr>
          <td>PIM parser invalid msg:</td>
          <td>{{indexData.pim_parser_invalid_data_message}}</td>
        </tr>
        <tr>
          <td>PIM parser last run:</td>
          <td>{{lastRun}}</td>
        </tr>
        <tr>
          <td>PIM parser valid data:</td>
          <td>{{indexData.pim_parser_valid_data}}</td>
        </tr>
        <tr>
          <td>Recent index:</td>
          <td>{{indexData.recent_index}}</td>
        </tr>
        <tr>
          <td>Recent PIM update:</td>
          <td>{{indexData.recent_pim_update}}</td>
        </tr>
      </tbody>
    </table>
  </p-accordionTab>
</p-accordion>
