<ng-container *ngFor="let shippingCost of shippingCosts">
  <h3>{{shippingCost.shipping_address.name}} {{shippingCost.shipping_address.city}}</h3>
  <table class="table table-striped">
    <tr>
      <td>{{'MIN_ORDER_AMOUNT_NOSHIPPING'|translate}}</td>
      <td [innerHTML]="shippingCost.minimum_order_amount.value|currencyConvert:shippingCost.minimum_order_amount?.currency"></td>
    </tr>
    <tr>
      <td>{{'SUBTOTAL_BIKE_PARTS'|translate}}</td>
      <td [innerHTML]="shippingCost.ordered_amount.value|currencyConvert:shippingCost.ordered_amount?.currency"></td>
    </tr>
    <tr>
      <td>{{'ACCELL_NL_RESTAMOUNT'|translate}}</td>
      <td [innerHTML]="shippingCost.to_order_amount.value|currencyConvert:shippingCost.to_order_amount?.currency"></td>
    </tr>
    <tr>
      <td>{{'SHIPPINGCOST'|translate}}</td>
      <td [innerHTML]="shippingCost.shipping_costs.value|currencyConvert:shippingCost.shipping_costs?.currency"></td>
    </tr>
  </table>
</ng-container>
