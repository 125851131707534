import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as moment from 'moment';
import { AccountService } from '../services/account.service';
import { SignedCondition } from '../interfaces/signed-condition';
import { PreorderService } from '../services/preorder.service';
import { ApplicationService } from '../services/application.service';

@Injectable({
  providedIn: 'root'
})
export class HasSignedPreorderNdaGuard implements CanActivate {

  constructor(private accountService: AccountService, private preorderService: PreorderService, private applicationService: ApplicationService) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    try {
      const sessionKey = `signed-preorder-${this.applicationService.getSelectCompanyGroupCode()}`;

      if (!["WG", "GHO"].includes(this.applicationService.getSelectCompanyGroupCode()) || sessionStorage.getItem(sessionKey) === "1") {
        return true;
      }
      const response: SignedCondition = await this.accountService.getNda("preorder", moment().year().toString(), true) as SignedCondition;
      if (response === null) {
        const agree: boolean = await this.preorderService.ndaModal() as boolean;
        if (agree) {
          sessionStorage.setItem(sessionKey, "1")
          return true;
        }
      } else {
        sessionStorage.setItem(sessionKey, "1")
      }
      return response !== null;
    } catch (error) {
      console.error("Error fetching NDA:", error);
      return false;
    }
  }
}
