<ng-template #popContent>
  <div
    class="d-flex my-2 my-sm-1 w-100"
    *ngIf="showShowRoomMode"
  >
    <div class="form-check form-switch d-flex align-items-center gap-2">
      <input
        type="checkbox"
        class="form-check-input mb-1"
        id="toggle_showroomMode"
        name="showroomMode"
        (change)="setShowroomModeFromCheckbox($event)"
        [(ngModel)]="showroomMode"
      />
      <label
        class="form-check-label justify-content-end "
        for="toggle_showroomMode"
        >{{ "SHOWROOM_MODE" | translate }}</label
      >
    </div>
  </div>
  <div
    class="d-flex my-2 my-sm-1 w-100"
  >
    <div class="form-check form-switch d-flex align-items-center gap-2">
      <input
        type="checkbox"
        class="form-check-input mb-1"
        id="toggle_accessibilityMode"
        name="accessibilityMode"
        (change)="setAccessibility($event)"
        [(ngModel)]="accessibilityMode"
      />
      <label
        class="form-check-label justify-content-end "
        for="toggle_accessibilityMode"
        >{{ "ACCESSIBILITY" | translate }}</label
      >
    </div>
  </div>
  <div class="d-flex my-2 my-sm-1 w-100" *ngIf="showShowRoomMode && companyGroupCode !== 'RAL'">
    <div class="form-check form-switch d-flex align-items-center gap-2">
      <input
        type="checkbox"
        class="form-check-input mb-1"
        id="toggle_gross"
        name="toggle_gross"
        (change)="setGrossPrice($event)"
        [(ngModel)]="grossPrice"
      />
      <label
        class="form-check-label justify-content-end "
        for="toggle_gross"
        >{{ "GROSSPRICE" | translate }}</label
      >
    </div>
  </div>
  <div class="d-flex my-2 my-sm-1 w-100" *ngIf="hasFullscreenSupport">
    <div class="form-check form-switch d-flex align-items-center gap-2">
      <input
        type="checkbox"
        class="form-check-input mb-1"
        id="toggle_fullscreen"
        name="toggle_fullscreen"
        (change)="setFullscreen($event)"
        [(ngModel)]="fullscreen"
      />
      <label
        class="form-check-label justify-content-end "
        for="toggle_fullscreen"
        >{{ "FULLSCREEN" | translate }}</label
      >
    </div>
  </div>
  <div class="d-flex my-2 my-sm-1 w-100 d-none d-sm-block">
    <div class="form-check form-switch d-flex align-items-center gap-2">
      <input
        type="checkbox"
        class="form-check-input mb-1"
        id="toggle_navbar"
        name="toggle_navbar"
        (change)="setNavbar($event)"
        [(ngModel)]="navbarNotFixed"
      />
      <label
        class="form-check-label justify-content-end "
        for="toggle_navbar"
        >{{ "NAVBAR_NOT_FIXED" | translate }}</label
      >
    </div>
  </div>
  <div
    class="d-flex my-2 my-sm-1 w-100"
    *ngIf="
      accountCode === 'ACCTEST01' &&
      applicationService.getSelectCompanyGroupCode() === 'ANL'
    "
  >
    <div class="form-check form-switch d-flex align-items-center gap-2">
      <input
        type="checkbox"
        class="form-check-input mb-1"
        id="toggle_kioskMode"
        name="toggle_kioskMode"
        (change)="setKioskMode($event)"
        [(ngModel)]="kioskMode"
      />
      <label
        class="form-check-label justify-content-end "
        for="toggle_kioskMode"
        >{{ "KIOSKMODE" | translate }}</label
      >
    </div>
  </div>
   <div
    class="d-flex my-2 my-sm-1 w-100"
  >
    <div class="form-check form-switch d-flex align-items-center gap-2">
      <input
        type="checkbox"
        class="form-check-input mb-1"
        id="toggle_darkMode"
        name="toggle_darkMode"
        (change)="setDarkMode($event)"
        [(ngModel)]="darkMode"
      />
      <label
        class="form-check-label justify-content-end "
        for="toggle_darkMode"
        >{{ "DARKMODE" | translate }}</label
      >
    </div>
  </div>
  <div
  class="d-flex my-2 my-sm-1 w-100"
  *ngIf="
  applicationService.getSelectCompanyGroupCode() === 'ANL'
"
>
  <div class="form-check form-switch d-flex align-items-center gap-2">
    <input
      type="checkbox"
      class="form-check-input mb-1"
      id="toggle_chat"
      name="toggle_chat"
      (change)="setChat($event)"
      [(ngModel)]="chatEnabled"
    />
    <label
      class="form-check-label justify-content-end "
      for="toggle_chat"
      >{{ "CHAT" | translate }}</label
    >
  </div>
</div>
  <div class="d-flex my-2 my-sm-1 w-100">
     <button class="btn btn-light my-2" (click)="toSettings()">{{"SETTINGS"|translate}}</button>
  </div>
</ng-template>
<ng-template #popTitle>{{ "QUICK_SETTINGS" | translate }}</ng-template>
<i
  class="pt-2 material-icons clickable "
  [ngClass]="[showroomMode ? 'text-success' : 'light', showShowRoomMode ? 'd-none d-sm-inline-block' : 'd-none']"
  #t="ngbTooltip"
  (click)="showroomMode = !showroomMode; setShowroomMode(showroomMode)"
  placement="bottom"
  [ngbTooltip]="'SHOWROOM_MODE' | translate"
>
  {{ showroomMode ? "toggle_on" : "toggle_off" }}
</i>
<span [placement]="placement" [ngbTooltip]="!p.isOpen() ? ('QUICK_SETTINGS' | translate) : ''" *ngIf="showQuickSettings">
  <i
    class="mt-0 me-3 material-icons light popoverTrigger mt-sm-1 settingsContainer"
    popoverClass="bg-dark "
    [ngbPopover]="popContent"
    [popoverTitle]="popTitle"
    (shown)="checkFullscreen()"
    #p="ngbPopover"
    [autoClose]="'outside'"
    placement="bottom"
    (click)="getShowShowRoomMode()"
    [ngClass]="[accessibilityMode ? 'accessibilityModeOn' : '']"
  >
    tune
  </i>
</span>
