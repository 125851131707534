import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { ApplicationService } from "src/app/services/application.service";
import { Router } from "@angular/router";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { timer } from "rxjs";
import { AccountService } from "src/app/services/account.service";

@Component({
  selector: "app-quick-settings",
  templateUrl: "./quick-settings.component.html",
  styleUrls: ["./quick-settings.component.scss"],
})
export class QuickSettingsComponent implements OnInit {
  showShowRoomMode = true;
  showQuickSettings = true;
  grossPrice: boolean;
  accessibilityMode: boolean;
  showroomMode: boolean;
  fullscreen: boolean;
  kioskMode: boolean;
  darkMode: boolean;
  hasFullscreenSupport: boolean;
  @Input() placement: string;
  companyGroupCode: string;
  @ViewChild("t") tooltip: NgbTooltip;
  @ViewChild("p") popover: NgbTooltip;
  accountCode: string;
  navbarNotFixed: boolean = false;
  showChat = false;
  chatEnabled = false;
  constructor(
    public applicationService: ApplicationService, private accountService: AccountService,
    private router: Router
  ) {}
  ngOnInit() {
    this.grossPrice = this.applicationService.getSetting(
      "showGrossPrice",
      this.grossPrice
    );
    this.getShowRoomMode();
    this.getShowShowRoomMode();
    this.showChat = this.applicationService.getSelectedLanguage() === "NL" && this.applicationService.getSelectCompanyGroupCode() === "ANL";
    this.showQuickSettings = !this.applicationService.isPartnerPortal();

    this.applicationService.changeEmitted$.subscribe((response) => {
      this.getShowShowRoomMode();
      this.getShowRoomMode();
    });

    if (localStorage.getItem("chat_disabled") === null) {
      this.chatEnabled = true;
    }

    this.companyGroupCode = this.applicationService.getSelectCompanyGroupCode();


    if (localStorage.getItem("navbar-not-fixed") === "1") {
      this.navbarNotFixed = true;
    } else {
      this.navbarNotFixed = false;
    }

    this.hasFullscreenSupport =
      window.document.fullscreenEnabled ||
      window.document["mozFullScreenEnabled"] ||
      window.document["msFullscreenEnabled"]
        ? true
        : false;

    if (this.hasFullscreenSupport) {
      this.fullscreen = window.document["fullscreenElement"] ? true : false;

      if (document.addEventListener) {
        document.addEventListener(
          "fullscreenchange",
          () => {
            this.fullscreen = window.document["fullscreenElement"]
              ? true
              : false;
          },
          false
        );
      }
    }

    // Show showroommode tooltip (first login only)
    if (!localStorage.getItem("introshown")) {
      timer(100).subscribe((e) => {
        this.tooltip.open();
      });
      timer(10000).subscribe((e) => {
        this.tooltip.close();
      });
      localStorage.setItem("introshown", "1");
    }

    // Get accountCode
    this.accountCode = localStorage.getItem("accountCode") ?? "";
    this.darkMode = localStorage.getItem("darkMode") ? true : false;
    this.setDarkMode(false)
  }

  getShowRoomMode() {
    this.showroomMode = this.applicationService.getShowroomMode(
      this.showroomMode
    );
  }

  checkFullscreen() {
    if (this.hasFullscreenSupport) {
      this.fullscreen =
        window["fullScreen"] || window.innerHeight == screen.height;
    }
  }

  getShowShowRoomMode() {
    this.showShowRoomMode = this.applicationService.getSetting(
      "showShowroomMode",
      "1"
    ) && !this.applicationService.isPartnerPortal()
  }

  setShowroomModeFromCheckbox(value) {
    this.applicationService.saveShowroomMode(value.srcElement.checked);
  }

  setShowroomMode(value) {
    this.applicationService.saveShowroomMode(value);
    if (window.location.href.includes("/home")) {
      this.router.navigateByUrl(`${this.applicationService.getSelectCompanyGroupCode()}/home}`)
    }
  }

  setFullscreen(value) {
    if (!window.document["fullscreenElement"]) {
      window.document.documentElement.requestFullscreen();
    } else {
      if (window.document.exitFullscreen) {
        window.document.exitFullscreen();
      }
    }
  }

  setAccessibility(value) {
    if (value.srcElement.checked) {
      window.document.body.className = "accMode";
    } else {
      window.document.body.className = "";
    }
  }

  setGrossPrice(value) {
    this.applicationService.saveShowGrossPrice(value.srcElement.checked);
  }

  setDarkMode(save) {
    document.documentElement.style.setProperty('color-scheme', this.darkMode ? 'dark' : 'light');

    if (this.darkMode) {
      if (save) {
        localStorage.setItem("darkMode", "1");
      }
    } else {
      if (save) {
        localStorage.removeItem("darkMode");
      }
    }

    const contentContainer = document.getElementById("content_container");
    if (this.darkMode) {
      document.body.classList.add("dark-theme");
      contentContainer.classList.add("custom");
    } else {
      document.body.classList.remove("dark-theme");
      if (!contentContainer.classList.contains("bf") && this.applicationService.getSelectCompanyGroupCode() !== "ANL") {
        contentContainer.classList.remove("custom");
      }
    }
  }

  setKioskMode(value) {
    this.applicationService.saveKioskMode(value.srcElement.checked);
    this.setShowroomMode(true);

    if (this.is_touch_enabled()) {
      this.setFullscreen(true);
    }

    this.router.navigateByUrl(
      `${this.applicationService.getSelectCompanyGroupCode()}/kiosk/home`
    );
  }

  is_touch_enabled() {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0;
  }

  toSettings() {
    this.popover.close();
    this.router.navigate([
      `${this.applicationService.getSelectCompanyGroupCode()}/settings`,
    ]);
  }

  setNavbar(value) {
    if (value.srcElement.checked) {
      localStorage.setItem("navbar-not-fixed", "1");
    } else {
      localStorage.setItem("navbar-not-fixed", "0");
    }
    window.location.reload();
  }

  setChat(value) {
    if (!value.srcElement.checked) {
      localStorage.setItem("chat_disabled", "1");
    } else {
      localStorage.removeItem("chat_disabled");
    }
    window.location.reload();
  }
}
